import React from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  dialogButtonConfirm,
  dialogEditSala,
  dialogViewSala
} from "../../../../consts/dictionary";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { closeDialogSala } from "../../../../reducers/dialogSala-reducer";
import FormEditSala from "./FormEditSala";
import { getSalaById } from "../../../../consts/urlAction";

const urlBase = window.API_URL;

export default function DialogEditSala({
  isOpen,
  rowInfo,
  isEdit
}) {
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [sala, setSala] = useState();
  const dispatch = useDispatch();

  // recupera i valori partendo da rowInfo
  useEffect(() => {
    if (isOpen) {
      (async () => {
        setIsRenderingDialog(true);

        const { idSala } = rowInfo;
        const { action, method } = getSalaById;
        let url = urlBase + action + idSala;

        try {
          const response = await axios({
            method: method,
            url,
          });

          if (response.data.ok) {
            setSala(response.data.result);
          }
        } catch (error) {
          console.error(error);
          if (error.response) {
            dispatch(
              notificationOpen({
                message: `${error.response.data.message}`,
                status: "error",
              })
            );
          } else {
            dispatch(
              notificationOpen({
                message: "Operazione fallita: Server non raggiungibile.",
                status: "error",
              })
            );
          }
        }
        setIsRenderingDialog(false);
      })();
    }
  }, [isOpen]);

  /*  function returnValue(value) {
     setSala(value);
   } */

  if (sala) {
    return (
      <>
        {!isRenderingDialog && (
          <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle sx={{ color: '#002E5D' }}>
              {isEdit ? dialogEditSala.DialogTitle : dialogViewSala.DialogTitle}
            </DialogTitle>
            <DialogContent sx={{ paddingRight: '1px!important', paddingBottom: '1px!important' }}>
              <FormEditSala sala={sala} />
            </DialogContent>
            {!isEdit && <> <DialogActions>
              <Button onClick={() => dispatch(closeDialogSala())}>
                {dialogButtonConfirm.Indietro}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="primaryButton"
                onClick={() => dispatch(closeDialogSala())}
              >
                {dialogButtonConfirm.Confirm}
              </Button>
            </DialogActions></>}
          </Dialog >
        )
        }
      </>
    );
  }
}
