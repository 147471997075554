import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import "../../index.css";
import { useSelector } from "react-redux";

export default function AccountInfo() {

  const userData = useSelector(state => state.userData);

  return (
    <>
      <Grid container className="cardInfoUtente" >
        <Grid item
          lg={12}
          md={12}
          xs={12}
          className="card-text-Primary">
          <Typography className="fontCard-Principal"><b>{userData.nome}</b></Typography>
        </Grid>
        {userData.ruoli && <Grid
          item
          lg={12}
          md={12}
          xs={12}
          className="card-text-Primary"
        >
          {userData.ruoli.map((ruolo) => {
            return (
              <Typography className="fontCard-Secondary">{ruolo.descrizione}</Typography>
            )
          })}
        </Grid>}
      </Grid>
      <Divider></Divider>
    </>
  );
}
