import React from "react";
import { useSelector } from "react-redux";
import { closeDialogSala } from "../../../reducers/dialogSala-reducer";
import { useDispatch } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";
import {
    HubEndpoint,
    dialogButtonConfirm,
} from "../../../consts/dictionary";
import { notificationOpen } from "../../../reducers/notification-reducer";
import handlerError from "../../../helpers/handlerError";
import { reloadingDataTableSale } from "../../../reducers/dataTableSale-reducer";
import DialogEditSala from "./DialogEditSala/DialogEditSala";
import DialogAddSala from "./DialogAddSala/DialogAddSala";
import { chiudiSOS, deleteSala, getRispostePredefiniteSos, inviaRispostaSos, resetSala } from "../../../consts/urlAction";
import { hideLoader, showLoader } from "../../../reducers/appLoader-reducer";
import axios from "axios";
import { useContext } from "react";
import ConnectionContext from "../../../context/ConnectionContext";

const urlBase = window.API_URL;

const SalaDialog = () => {
    const dialogState = useSelector((state) => state.dialogSala);
    const connection = useContext(ConnectionContext);
    const backButtonState = useSelector((state) => state.dialogBackButton);
    const dispatch = useDispatch();
    const { rowInfo, dialogType, dialogPageType } = dialogState;
    const [presetAnswer, setPresetAnswer] = useState('');
    const [customAnswer, setCustomAnswer] = useState('');
    const [disablePresetAnswer, setDisablePresetAnswer] = useState(false);
    const [disableCustomAnswer, setDisableCustomAnswer] = useState(false);
/*     const [disableWarnings, setDisableWarnings] = useState(false);
 */    const [sosAnswers, setSosAnswers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [changedAnswer, setChangedAnswer] = useState();
    const [errorAnswer, setErrorAnswer] = useState(false);
    //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
    useEffect(() => {
        dispatch(closeDialogSala());
    }, [window.onhashchange]);

    useEffect(() => {
        fetchSosAnswers();
    }, [dialogState]);

    const fetchSosAnswers = async () => {
        let isMounted = true;
        dispatch(showLoader());

        (async () => {
            setLoading(true);

            const { action, method } = getRispostePredefiniteSos;
            let url = urlBase + action;

            try {
                const response = await axios({
                    method: method,
                    url
                });

                if (isMounted) {
                    const newRows = response.data.result;
                    setSosAnswers(newRows);
                    setLoading(false);
                    dispatch(hideLoader());
                }
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
                setLoading(false);
                dispatch(hideLoader());
            }
        })();
        return () => {
            isMounted = false;
        };
    };

    useEffect(() => {
        dispatch(closeDialogSala());
    }, [backButtonState]);

    const handleResetMiniPc = (rowInfo) => {
        let idSala = rowInfo.idSala;

        (async () => {

            dispatch(showLoader());
            try {
                connection.invoke(HubEndpoint.RiavviaMiniPc, idSala);
                dispatch(reloadingDataTableSale());
                dispatch(closeDialogSala());
                dispatch(
                    notificationOpen({
                        message: "Operazione eseguita con successo.",
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
            dispatch(hideLoader());
        })();
    }

    const handleCloseSOS = (rowInfo) => {
        let idSala = rowInfo.idSala;

        (async () => {
            let action, method, url;
            action = chiudiSOS.action + idSala;
            method = chiudiSOS.method;
            url = urlBase + action;

            dispatch(showLoader());
            try {
                const response = await axios({
                    method: method,
                    url,
                });
                if (response.data.ok) {
                    //dispatch(reloadingDataTableSale());
                    dispatch(closeDialogSala());
                }
                dispatch(
                    notificationOpen({
                        message: response.data.message,
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
            dispatch(hideLoader());
        })();
    }

    const handleDeleteSala = (rowInfo) => {
        let idSala = rowInfo.idSala;

        (async () => {
            let action, method, url;
            action = deleteSala.action + idSala;
            method = deleteSala.method;
            url = urlBase + action;

            dispatch(showLoader());
            try {
                const response = await axios({
                    method: method,
                    url,
                    /*                     data: {
                                            idSala: idSala
                                        } */
                });
                if (response.data.ok) {
                    dispatch(reloadingDataTableSale());
                    dispatch(closeDialogSala());
                }
                dispatch(
                    notificationOpen({
                        message: response.data.message,
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
            dispatch(hideLoader());
        })();
    }

    const handleResetPostazioneSala = (rowInfo) => {
        let idSala = rowInfo.idSala;

        (async () => {
            let action, method, url;
            action = resetSala.action + idSala;
            method = resetSala.method;
            url = urlBase + action;

            dispatch(showLoader());
            try {
                const response = await axios({
                    method: method,
                    url
                });
                if (response.data.ok) {
                    dispatch(reloadingDataTableSale());
                    dispatch(closeDialogSala());
                }
                dispatch(
                    notificationOpen({
                        message: response.data.message,
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
            dispatch(hideLoader());
        })();
    }

    useEffect(() => {
        if (changedAnswer != null) {
            (async () => {
                let body, action, method, url;
                body = {
                    ...changedAnswer,
                };

                action = inviaRispostaSos.action;
                method = inviaRispostaSos.method;

                url = urlBase + action;
                dispatch(showLoader())
                try {
                    const response = await axios({
                        method: method,
                        url,
                        data: body,
                    });

                    if (response.data.ok) {
                        dispatch(reloadingDataTableSale());
                        dispatch(closeDialogSala());
                    }
                    dispatch(
                        notificationOpen({
                            message: response.data.message,
                            status: response.data.ok ? "success" : "error",
                        })
                    );
                } catch (error) {
                    console.error(error);
                    const notificationError = handlerError(error);
                    dispatch(notificationOpen(notificationError));
                }
                dispatch(hideLoader())
                setDisablePresetAnswer(false);
                setDisableCustomAnswer(false);
            })();
        }
    }, [changedAnswer]);

    const handleSosAnswer = (rowInfo) => {
        if (presetAnswer === '' && customAnswer === '') {
            setErrorAnswer(true);
        } else {
            setChangedAnswer({
                idSala: rowInfo.idSala,
                TestoRisposta: disablePresetAnswer ? customAnswer : presetAnswer,
            });
            setPresetAnswer('');
            setCustomAnswer('');
            setDisableCustomAnswer(false);
            setDisablePresetAnswer(false);
            /*             setDisableWarnings(false);
             */
        }
    }

    const handleChangeCustomAnswer = (event) => {
        setErrorAnswer(false);
        setCustomAnswer(event.target.value);
        const customAnswer = event.target.value;
        if (customAnswer !== '') {
            setDisablePresetAnswer(true);
        }
        else {
            setDisablePresetAnswer(false);
        }
    };

    const handleChangePresetAnswer = (event) => {
        setErrorAnswer(false);
        setPresetAnswer(event.target.value);
        const presetAnswer = event.target.value;
        if (presetAnswer !== "") {
            //setDisableCustomAnswer(true);
        } else {
            //setDisableCustomAnswer(false);
        }
    };

    /* const handleDisableWarnings = (event) => {
        const checkDisableWarnings = event.target.checked;
        if (checkDisableWarnings === true) {
            setDisableWarnings(true);
        } else {
            setDisableWarnings(false);

        }
    } */

    if (sosAnswers) {
        return (
            <>
                {dialogState.dialogType === "chiudiSOS" && (
                    <>
                        <Dialog
                            open={dialogState.isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={"sm"}
                        >
                            <DialogTitle sx={{ color: '#002E5D' }}>Chiudi S.O.S.</DialogTitle>
                            <DialogContent className="border-Content-dialog">
                                <DialogContentText id="alert-dialog-description">
                                    Chiudere l'S.O.S. della sala <b>{rowInfo.nomeSala}</b>?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ color: '#002E5D!important' }} onClick={() => dispatch(closeDialogSala())}>
                                    {dialogButtonConfirm.Cancel}
                                </Button>
                                <Button
                                    className="primaryButton"
                                    variant="contained"
                                    onClick={() => handleCloseSOS(rowInfo)}
                                >
                                    {dialogButtonConfirm.Confirm}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                {dialogState.dialogType === "resetPC" && (
                    <>
                        <Dialog
                            open={dialogState.isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={"sm"}
                        >
                            <DialogTitle sx={{ color: '#002E5D' }}>Riavvio Mini Pc</DialogTitle>
                            <DialogContent className="border-Content-dialog">
                                <DialogContentText id="alert-dialog-description">
                                    Riavviare il Mini Pc della Sala <b>{rowInfo.nomeSala}</b>?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ color: '#002E5D!important' }} onClick={() => dispatch(closeDialogSala())}>
                                    {dialogButtonConfirm.Cancel}
                                </Button>
                                <Button
                                    className="primaryButton"
                                    variant="contained"
                                    onClick={() => handleResetMiniPc(rowInfo)}
                                >
                                    {dialogButtonConfirm.Confirm}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                {dialogState.dialogType === "resetSala" && (
                    <>
                        <Dialog
                            open={dialogState.isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={"sm"}
                        >
                            <DialogTitle sx={{ color: '#002E5D' }}>Reset Postazione di Sala</DialogTitle>
                            <DialogContent className="border-Content-dialog">
                                <DialogContentText id="alert-dialog-description">
                                    Resettare la postazione della sala <b>{rowInfo.nomeSala}</b>?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ color: '#002E5D!important' }} onClick={() => dispatch(closeDialogSala())}>
                                    {dialogButtonConfirm.Cancel}
                                </Button>
                                <Button
                                    className="primaryButton"
                                    variant="contained"
                                    onClick={() => handleResetPostazioneSala(rowInfo)}
                                >
                                    {dialogButtonConfirm.Confirm}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                {dialogState.dialogType === "warnings" && (
                    <>
                        <Dialog
                            open={dialogState.isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={"sm"}
                        >
                            <DialogTitle sx={{ color: '#002E5D' }}>Problematiche rilevate </DialogTitle>
                            <DialogContent className="border-Content-dialog">
                                <DialogContentText id="alert-dialog-description">
                                    <ul>
                                        {
                                            rowInfo.warnings.map((warning) => {
                                                return (
                                                    <li><b>{warning}</b></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ color: '#002E5D!important' }} onClick={() => dispatch(closeDialogSala())}>
                                    {dialogButtonConfirm.Indietro}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                {dialogState.dialogType === "rispostaSOS" && (
                    <>
                        <Dialog
                            open={dialogState.isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={"md"}
                        >
                            <DialogTitle sx={{ color: '#002E5D' }}>Risposta S.O.S. </DialogTitle>
                            <DialogContent className="border-Content-dialog">
                                <DialogContentText id="alert-dialog-description">
                                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                        <InputLabel id="label-select-answer">Risposta predefinita</InputLabel>
                                        <Select
                                            labelId="label-select-answer"
                                            id="select-answer"
                                            value={presetAnswer}
                                            onChange={handleChangePresetAnswer}
                                            label="Answer"
                                            disabled={disablePresetAnswer}
                                            error={errorAnswer}
                                        >
                                            <MenuItem value="">
                                                --
                                            </MenuItem>
                                            {
                                                sosAnswers.map((answer) => {
                                                    return (<MenuItem value={answer.descrizione}>{answer.descrizione}</MenuItem>)
                                                })
                                            }
                                        </Select>
                                        <TextField
                                            sx={{ mt: 3 }}
                                            id="standard-multiline-static"
                                            label="Risposta personalizzata"
                                            multiline
                                            rows={4}
                                            placeholder="Digita una risposta qui..."
                                            variant="filled"
                                            disabled={disableCustomAnswer}
                                            helperText="Digitando una risposta personalizzata verrà ignorata la selezione della risposta predefinita."
                                            onChange={handleChangeCustomAnswer}
                                            error={errorAnswer}
                                        />
                                        {/*                                         <FormControlLabel control={
                                            <Checkbox
                                                checked={disableWarnings}
                                                onChange={(e) => { handleDisableWarnings(e) }}
                                                color="primary"
                                            />}
                                            margin="dense"
                                            label="Disattiva warning"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                            sx={{ marginTop: '1rem' }}
                                        /> */}
                                        {errorAnswer && <p style={{ color: '#d32f2f', fontSize: '12px' }}>Fornire almeno un tipo di risposta.</p>}
                                    </FormControl>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ color: '#002E5D!important' }} onClick={() => {
                                    setErrorAnswer(false);
                                    setPresetAnswer('');
                                    setCustomAnswer('')
                                    setDisableCustomAnswer(false);
                                    setDisablePresetAnswer(false);
                                    dispatch(closeDialogSala());
/*                                     setDisableWarnings(false);
 */                                }}>
                                    {dialogButtonConfirm.Indietro}
                                </Button>
                                <Button
                                    className="primaryButton"
                                    variant="contained"
                                    onClick={() => handleSosAnswer(rowInfo)}
                                >
                                    {dialogButtonConfirm.Confirm}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
                {(dialogState.dialogType === "edit") && (
                    <DialogEditSala
                        isEdit={dialogState.isEdit}
                        isOpen={dialogState.isOpen}
                        rowInfo={rowInfo}
                    />
                )}
                {(dialogState.dialogType === "add") && (
                    <DialogAddSala
                        isEdit={dialogState.isEdit}
                        isOpen={dialogState.isOpen}
                        rowInfo={rowInfo}
                        fullWidth
                        maxWidth={"lg"}
                    />
                )}
                {dialogState.dialogType === "delete" && (
                    <>
                        <Dialog
                            open={dialogState.isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth={"sm"}
                        >
                            <DialogTitle sx={{ color: '#002E5D' }}>Eliminazione sala</DialogTitle>
                            <DialogContent className="border-Content-dialog">
                                <DialogContentText id="alert-dialog-description">
                                    Eliminare la sala <b>{rowInfo.nomeSala}</b>?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button sx={{ color: '#002E5D!important' }} onClick={() => dispatch(closeDialogSala())}>
                                    {dialogButtonConfirm.Cancel}
                                </Button>
                                <Button
                                    className="primaryButton"
                                    variant="contained"
                                    onClick={() => handleDeleteSala(rowInfo)}
                                >
                                    {dialogButtonConfirm.Confirm}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )}
            </>
        );
    }
};

export default SalaDialog;
