import { useLocation, useNavigate } from 'react-router-dom';
import Notification from "../../components/Notification";
import SlidesDataTable from "./SlidesDataTable/SlidesDataTable";
import { Grid, Breadcrumbs } from "@mui/material";
import { StyledBreadcrumb } from "../../components/Breadcrumb/StyledBreadcrumb";
import { InternalAppRoutes } from "../../consts/dictionary";
import HomeIcon from '@mui/icons-material/Home';

const Guida = () => {
    
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const idSala = searchParams.get('idSala');

    return (
        <>
            <Grid container>
                <Breadcrumbs mb={2} aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label="Sale"
                        onClick={() => { navigate(InternalAppRoutes.Sale) }}
                        icon={<HomeIcon fontSize="small" />
                        }
                    />
                    <StyledBreadcrumb label="Guida" />
                </Breadcrumbs>
                <SlidesDataTable idSala={idSala}/>
            </Grid>
            <Notification />
        </>
    );
};

export default Guida;