import { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { dialogCloneSlide } from "../../../../consts/dictionary";
import FormCloneSlides from "./FormCloneSlides";

const DialogCloneSlides = ({isOpen, idSala}) => {

    const [isRenderingDialog, setIsRenderingDialog] = useState(false);

    return (
        <>
          {!isRenderingDialog && (
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ color: '#002E5D' }}>
                    {dialogCloneSlide.DialogTitle}
                </DialogTitle>
                <DialogContent sx={{ paddingRight: '1px!important', paddingBottom: '1px!important' }}>
                    <FormCloneSlides idSala={idSala}/>
                </DialogContent>
            </Dialog >
          )}
        </>
    );
}

export default DialogCloneSlides;