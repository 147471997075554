import { createTheme } from '@material-ui/core/styles';
import { Palette } from './dictionary';

export const theme = createTheme({
    palette: {
      primary: {
        main: Palette.PrimaryINAIL, // Colore primary personalizzato
      },
      error: {
        main: Palette.Error, // Colore alert personalizzato
      },
    },
  });
  