import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../reducers/appLoader-reducer";
import { notificationOpen } from "../../reducers/notification-reducer";
import { loginUser } from "../../consts/urlAction";
import { CDCardContent } from "../../consts/CDCardStyles";
import { initialValuesLogin } from "../../consts/formik/Login/initialValuesLogin";
import { validationSchemaLogin } from "../../consts/formik/Login/validationSchemaLogin";
import { InternalAppRoutes } from "../../consts/dictionary";
import Notification from "../../components/Notification";
import handlerError from "../../helpers/handlerError";
import Logo from "../../assets/Logo.png";
import "../../index.css";

const urlBase = window.API_URL;

const Login = () => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const state = useSelector((state) => state.userData);
    const [changedRow, setChangedRow] = useState();
    let initialValues = initialValuesLogin();
    let validationSchema = validationSchemaLogin;

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setChangedRow({
                username: values.username,
                password: values.password
            });
        }
    });

    useEffect(() => {
        let isMounted = true;
        if (changedRow != null) {
            (async () => {
                dispatch(showLoader());
                let body = changedRow;
                const { action, method } = loginUser;
                let url = urlBase + action;
                try {
                    const response = await axios({
                        method: method,
                        url,
                        data: body
                    });
                    if(response.data.ok){
                      navigate(InternalAppRoutes.Sale)
                    }                   
                } catch (error) {
                    console.error(error);
                    const notificationError = handlerError(error);
                    dispatch(notificationOpen(notificationError));
                    dispatch(hideLoader());
                }
                if (isMounted) {
                    dispatch(hideLoader());
                }
            })();
        }
        return () => {
            isMounted = false;
            dispatch(hideLoader());
        };
    }, [changedRow]);

    useEffect(() => {
      dispatch(hideLoader());
    },[]);


  return Object.entries(state).length !== 0 ? (
    <Navigate to="/sale" />
  ) : (
    <>
        <Grid container component="main">
          <Grid item xs={12} lg={12}>
            <Box className="formLoginVisualization">
              <Box mb={3}>
                <img src={Logo} alt="Logo"/>
              </Box> 
              <CDCardContent className="loginCard">
                <form onSubmit={formik.handleSubmit} className="formSize">
                  <Box className="flexEndVisualization">
                    <PersonIcon className="formIcon" />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      autoComplete="username"
                      variant="standard"
                      value={formik.values.username}
                      onChange={formik.handleChange("username")}
                      error={
                        formik.touched.username && Boolean(formik.errors.username)
                      }
                      helperText={
                        formik.touched.username && formik.errors.username
                      }
                    />
                  </Box>
                  <Box className="flexEndVisualization">
                    <KeyIcon className="formIcon" />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      variant="standard"
                      value={formik.values.password}
                      onChange={formik.handleChange("password")}
                      error={
                        formik.touched.password && Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Box>
                  <Box className="centerVisualization" mt={3} mb={2}>
                    <Button type="submit" variant="contained" className="primaryButton">
                      Login
                    </Button>
                  </Box>
                </form>
              </CDCardContent>
            </Box>
          </Grid>
        </Grid>
      <Notification />
    </>
  );
}

export default Login;
