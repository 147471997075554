import * as React from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Divider, Typography, TextField, Button, FormControl, Breadcrumbs } from "@mui/material";
import { CDCard, CDCardContent, CDCardHeader } from "../../consts/CDCardStyles";
import handlerError from "../../helpers/handlerError";
import { notificationOpen } from "../../reducers/notification-reducer";
import Notification from "../../components/Notification";
import { StyledBreadcrumb } from "../../components/Breadcrumb/StyledBreadcrumb";
import { InternalAppRoutes } from "../../consts/dictionary";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import "../../index.css";
import { hideLoader, showLoader } from "../../reducers/appLoader-reducer";
import axios from "axios";
import { useEffect } from "react";
import { editImpostazioniGlobali, getImpostazioniGlobali } from "../../consts/urlAction";
import { initialValuesSettingsEdit } from "../../consts/formik/Settings/initialValuesSettings";
import { validationSchemaSettingsEdit } from "../../consts/formik/Settings/validationSchemaSettings";

const urlBase = window.API_URL;

const Settings = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [reloading, setReloading] = useState(true);
    const [changedRow, setchangedRow] = useState();
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState();

    useEffect(() => {
        if (reloading) {
            fetchSettings();
        }
        setReloading(false);
    }, [reloading]);

    const fetchSettings = async () => {
        let isMounted = true;
        dispatch(showLoader());

        (async () => {
            setLoading(true);

            const { action, method } = getImpostazioniGlobali;
            let url = urlBase + action;

            try {
                const response = await axios({
                    method: method,
                    url
                });

                if (isMounted) {
                    const newRows = response.data.result;
                    setSettings(newRows);
                    setLoading(false);
                    dispatch(hideLoader());
                }
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
                setLoading(false);
                dispatch(hideLoader());
            }
        })();
        return () => {
            isMounted = false;
        };
    };

    let initialValues = settings ? initialValuesSettingsEdit(settings) : {
        tempoSos: 1,
        tempoSelezioneMenu: 1,
        tempoSelezioneMeeting: 1,
        tempoAttesaRipristinoSessione: 1
    };
    let validationSchema = validationSchemaSettingsEdit;

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setchangedRow({
                TempoSos: values.tempoSos,
                TempoSelezioneMenu: values.tempoSelezioneMenu,
                TempoSelezioneMeeting: values.tempoSelezioneMeeting,
                TempoAttesaRipristinoSessione: values.tempoAttesaRipristinoSessione
            });
        }
    });

    useEffect(() => {
        if (changedRow != null) {
            (async () => {
                let body, action, method, url;
                body = {
                    ...changedRow,
                };

                action = editImpostazioniGlobali.action;
                method = editImpostazioniGlobali.method;

                url = urlBase + action;
                dispatch(showLoader())
                try {
                    const response = await axios({
                        method: method,
                        url,
                        data: body
                    });

                    if (response.data.ok) {
                        fetchSettings();
                    }
                    dispatch(
                        notificationOpen({
                            message: response.data.message,
                            status: response.data.ok ? "success" : "error",
                        })
                    );
                } catch (error) {
                    console.error(error);
                    const notificationError = handlerError(error);
                    dispatch(notificationOpen(notificationError));
                    if (error?.response?.data?.errors != undefined) {
                        let keys = Object.keys(error?.response?.data?.errors);
                        keys.map((key) => {
                            formik.setFieldError(key, error?.response?.data?.errors[key]);
                        });
                    }
                }
                dispatch(hideLoader())
            })();
        }
    }, [changedRow]);

    if (formik.values) {
        return (
            <>
                <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        label="Sale"
                        onClick={() => { navigate(InternalAppRoutes.Sale) }}
                        icon={<HomeIcon fontSize="small" />
                        }
                    />
                    <StyledBreadcrumb label="Configurazioni" />
                </Breadcrumbs>
                <Grid container sx={{justifyContent: 'center'}}>
                    <Grid container item xs={6} sm={6} md={6}>
                        <Grid item component={CDCard} xs={12} sm={12} mt={2}>
                            <CDCardHeader
                                title={
                                    <>
                                        <Typography className="boldBlueText">Configurazioni Globali</Typography>
                                    </>
                                }
                            />
                            <Divider />
                            <CDCardContent>
                                <form onSubmit={formik.handleSubmit}>
                                    <FormControl fullWidth>
                                        <Grid container rowSpacing={1} columnSpacing={2}>
                                            <Grid item xs={6} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    id="tempoSos"
                                                    label="Tempo SOS Sala (secondi)"
                                                    name="tempoSos"
                                                    autoComplete="tempoSos"
                                                    variant="standard"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: "0" } }}
                                                    value={formik.values.tempoSos}
                                                    className="formInput"
                                                    onChange={formik.handleChange("tempoSos")}
                                                    onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault() }
                                                    error={
                                                        formik.touched.tempoSos && Boolean(formik.errors.tempoSos)
                                                    }
                                                    helperText={
                                                        formik.touched.tempoSos && formik.errors.tempoSos
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    id="tempoSelezioneMenu"
                                                    label="Tempo Selezione Menu (secondi)"
                                                    name="tempoSelezioneMenu"
                                                    autoComplete="tempoSelezioneMenu"
                                                    variant="standard"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: "0" } }}
                                                    value={formik.values.tempoSelezioneMenu}
                                                    className="formInput"
                                                    onChange={formik.handleChange("tempoSelezioneMenu")}
                                                    onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault() }
                                                    error={
                                                        formik.touched.tempoSelezioneMenu && Boolean(formik.errors.tempoSelezioneMenu)
                                                    }
                                                    helperText={
                                                        formik.touched.tempoSelezioneMenu && formik.errors.tempoSelezioneMenu
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    id="tempoAttesaRipristinoSessione"
                                                    label="Tempo Attesa Ripristino Sessione (secondi)"
                                                    name="tempoAttesaRipristinoSessione"
                                                    autoComplete="tempoAttesaRipristinoSessione"
                                                    variant="standard"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: "0" } }}
                                                    value={formik.values.tempoAttesaRipristinoSessione}
                                                    className="formInput"
                                                    onChange={formik.handleChange("tempoAttesaRipristinoSessione")}
                                                    onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault() }
                                                    error={
                                                        formik.touched.tempoAttesaRipristinoSessione && Boolean(formik.errors.tempoAttesaRipristinoSessione)
                                                    }
                                                    helperText={
                                                        formik.touched.tempoAttesaRipristinoSessione && formik.errors.tempoAttesaRipristinoSessione
                                                    }
                                                />
                                            </Grid>
                                           {/*  <Grid item xs={4} sm={4}>
                                                <TextField
                                                    fullWidth
                                                    margin="normal"
                                                    id="tempoSelezioneMeeting"
                                                    label="Tempo Selezione Meeting"
                                                    name="tempoSelezioneMeeting"
                                                    autoComplete="tempoSelezioneMeeting"
                                                    variant="standard"
                                                    type="number"
                                                    InputProps={{ inputProps: { min: "0" } }}
                                                    value={formik.values.tempoSelezioneMeeting}
                                                    className="formInput"
                                                    onChange={formik.handleChange("tempoSelezioneMeeting")}
                                                    onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault() }
                                                    error={
                                                        formik.touched.tempoSelezioneMeeting && Boolean(formik.errors.tempoSelezioneMeeting)
                                                    }
                                                    helperText={
                                                        formik.touched.tempoSelezioneMeeting && formik.errors.tempoSelezioneMeeting
                                                    }
                                                />
                                            </Grid> */}
                                        </Grid>
                                    </FormControl>
                                    <Grid container item className="rightVisualization" mt={3}>
                                        <Button type="submit"
                                            variant="contained"
                                            disabled={!(formik.dirty)}
                                            className={!(formik.dirty) ? "disabledButton" : "primaryButton"} 
                                            sx={{margin: '-0.4rem!important'}}
                                            >
                                            Salva
                                        </Button>
                                    </Grid>
                                </form>
                            </CDCardContent>
                        </Grid>
                    </Grid>
                </Grid>
                <Notification />
            </>
        );
    }
};

export default Settings;