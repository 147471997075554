import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false , isEdit: true, dialogType:"", dialogPageType:"", rowInfo:-1};

const slideSlice = createSlice({
    name:'dialogSlide',
    initialState,
    reducers: {
         openEmptyDialogSlide: (state, payload) => { 
            return {...state, isOpen: true, dialogType:payload.payload};
         },
         setPageType: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogSlide:(state, payload) => {
             return {...state, isOpen:true, dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo, isEdit:payload.payload.isEdit}
         },
         openDialogMoveSlide: (state, payload) => {
            return {...state, isOpen: true, dialogType:payload.payload.dialogType, slideInfo:payload.payload.slideInfo, maxPosition: payload.payload.maxPosition, slides: payload.payload.slides};
         },
         closeDialogSlide: (state) => {
            return {...state, isOpen: false};
         },
         closeDialogMoveSlide: (state) => {
            return {...state, isOpen: false, slideInfo: {}, maxPosition: 0, slides: []};
         }    
    }
});

export const { openDialogSlide, closeDialogSlide, openEmptyDialogSlide, setPageType, openDialogMoveSlide, closeDialogMoveSlide } = slideSlice.actions;

const { reducer } = slideSlice;

export default reducer;