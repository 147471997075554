import { useNavigate } from 'react-router-dom';
import { refreshToken, logoutUser } from "../reducers/userData-reducer";
import { checkAnonymousPathsIncludedInRequest } from "./checkAnonymousPathsIncludedInRequest";
import { RequestResponseHeaders } from '../consts/dictionary';
import axios from 'axios';
import moment from 'moment';
import store from "../store/index";

const allowAnonymousPath = ["User/Login"];

const Interceptor = (dispatch) => {

    const navigate = useNavigate();

    axios.interceptors.request.use(
        (request) => {

            request = {
                ...request,
                transformRequest: [(data) => {
                    for (let key in data) {
                        if (data[key] instanceof Date) {
                            data[key] = moment(data[key]).format();
                        }
                    }

                    return data;
                }, ...axios.defaults.transformRequest]
            };

            const urlRequest = request.url;
            const state = store.getState();
            const { token, scadenzaToken } = state.userData;

            if (checkAnonymousPathsIncludedInRequest(allowAnonymousPath, urlRequest)) return request;

            if (checkToken(scadenzaToken)) {
                request.headers[RequestResponseHeaders.Authorization] = `Bearer ${token}`;
            }
            else {
                dispatch(logoutUser());
                navigate("/")
            }

            return request;

        },
        (error) => { return Promise.reject(error); }
    );

    axios.interceptors.response.use(
        (response) => {
            
            let urlRequest = response.request.responseURL;

            if (urlRequest.includes("User/Login")) {
                let loggedUser = response.data.result;
                localStorage.setItem('cmsroomsmeetinguser', JSON.stringify(loggedUser));
                dispatch(refreshToken(loggedUser, navigate));
            }
            else {
                const newToken = response.headers[RequestResponseHeaders.HeaderToken];
                const expireDate = response.headers[RequestResponseHeaders.HeaderExpireToken]?.replaceAll("\"", "");
                const state = store.getState();
                const { userData } = state;

                let updatedUser = { ...userData, token: newToken, scadenzaToken: expireDate };
                if (JSON.stringify(userData) !== JSON.stringify(updatedUser)) {
                    dispatch(refreshToken(updatedUser, navigate));
                    localStorage.setItem('cmsroomsmeetinguser', JSON.stringify(updatedUser));
                }
            }

            return response;
        },
        (error) => { return Promise.reject(error); }
    );

}

export const checkToken = (expireToken) => {
    let now = Date.now();
    let expireDate = new Date(expireToken);
    if (now < expireDate) {
        return true;
    }

    return false;
}

export default Interceptor;