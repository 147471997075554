import {
    Button,
    DialogActions,
    FormControl,
    Grid,
    TextField,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    dialogEditSala,
    dialogButtonGeneral
} from "../../../../consts/dictionary";
import { initialValuesSalaEdit } from "../../../../consts/formik/Sala/initialValuesSala";
import { validationSchemaSalaEdit } from "../../../../consts/formik/Sala/validationSchemaSala";
import { closeDialogSala } from "../../../../reducers/dialogSala-reducer";
import axios from "axios";
import handlerError from "../../../../helpers/handlerError";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { reloadingDataTableSale } from "../../../../reducers/dataTableSale-reducer";
import { useFormik } from "formik";
import { editSala } from "../../../../consts/urlAction";
import { hideLoader, showLoader } from "../../../../reducers/appLoader-reducer";

const urlBase = window.API_URL;

export default function FormEditSala({
    sala,
    isOpen = false,
    returnIsOpen,
}) {
    const [changedRow, setchangedRow] = useState();
    const dispatch = useDispatch();
    let initialValues = initialValuesSalaEdit(sala);
    let validationSchema = validationSchemaSalaEdit;

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
             setchangedRow({
                IdSala: values.idSala,
                NomeSala: values.nomeSala,
                AccountMicrosoft: values.accountMicrosoft,
                //IndirizzoIp: values.indirizzoIp,
                NomeSede: values.nomeSede,
                IndirizzoSede: values.indirizzoSede
             }); 
            //formik.resetForm(initialValues);
            dispatch(reloadingDataTableSale());
            dispatch(closeDialogSala());
/*             dispatch(
                notificationOpen({
                    message: "Risposta inviata con successo",
                    status: "success"
                })
            ); */
        },
    });

    const closeDialog = () => {
        formik.resetForm(initialValues);
        dispatch(closeDialogSala());
    };

    useEffect(() => {
        if (changedRow != null) {
            (async () => {
                let body, action, method, url;
                body = {
                    ...changedRow,
                };

                action = editSala.action;
                method = editSala.method;

                url = urlBase + action;
                dispatch(showLoader())
                try {
                    const response = await axios({
                        method: method,
                        url,
                        data: body,
                    });

                    if (response.data.ok) {
                        formik.resetForm(initialValues);
                        dispatch(reloadingDataTableSale());
                        dispatch(closeDialogSala());
                    }
                    dispatch(
                        notificationOpen({
                            message: response.data.message,
                            status: response.data.ok ? "success" : "error",
                        })
                    );
                } catch (error) {
                    console.error(error);
                    const notificationError = handlerError(error);
                    dispatch(notificationOpen(notificationError));
                    if (error?.response?.data?.errors != undefined) {
                        let keys = Object.keys(error?.response?.data?.errors);
                        keys.map((key) => {
                            formik.setFieldError(key, error?.response?.data?.errors[key]);
                        });
                    }
                }
                dispatch(hideLoader())
            })();
        }
    }, [changedRow]); 

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormControl fullWidth >
                    <Grid container rowSpacing={1} columnSpacing={2} sx={{ paddingRight: '24px!important' }}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="nomeSala"
                                label="Nome"
                                type="text"
                                variant="standard"
                                value={formik.values.nomeSala}
                                onChange={formik.handleChange("nomeSala")}
                                error={
                                    formik.touched.nomeSala && Boolean(formik.errors.nomeSala)
                                }
                                helperText={formik.touched.nomeSala && formik.errors.nomeSala}
                                inputProps={{
                                    maxLength: 150,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="accountMicrosoft"
                                label="Account Microsoft"
                                type="text"
                                variant="standard"
                                value={formik.values.accountMicrosoft}
                                onChange={formik.handleChange("accountMicrosoft")}
                                error={
                                    formik.touched.accountMicrosoft && Boolean(formik.errors.accountMicrosoft)
                                }
                                helperText={formik.touched.accountMicrosoft && formik.errors.accountMicrosoft}
                                inputProps={{
                                    maxLength: 150,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                            disabled
                                fullWidth
                                margin="dense"
                                id="indirizzoIp"
                                label="Indirizzo Ip Mini PC"
                                type="text"
                                variant="standard"
                                value={formik.values.indirizzoIp}
                                onChange={formik.handleChange("indirizzoIp")}
                                error={
                                    formik.touched.indirizzoIp && Boolean(formik.errors.indirizzoIp)
                                }
                                helperText={formik.touched.indirizzoIp && formik.errors.indirizzoIp}
                                inputProps={{
                                    maxLength: 150
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="nomeSede"
                                label="Nome Sede"
                                type="text"
                                variant="standard"
                                value={formik.values.nomeSede}
                                onChange={formik.handleChange("nomeSede")}
                                error={
                                    formik.touched.nomeSede && Boolean(formik.errors.nomeSede)
                                }
                                helperText={formik.touched.nomeSede && formik.errors.nomeSede}
                                inputProps={{
                                    maxLength: 150,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="indirizzoSede"
                                label="Indirizzo Sede"
                                type="text"
                                variant="standard"
                                value={formik.values.indirizzoSede}
                                onChange={formik.handleChange("indirizzoSede")}
                                error={
                                    formik.touched.indirizzoSede && Boolean(formik.errors.indirizzoSede)
                                }
                                helperText={formik.touched.indirizzoSede && formik.errors.indirizzoSede}
                                inputProps={{
                                    maxLength: 150,
                                }}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
                <DialogActions sx={{ mt: 3 }}>
                    <Button sx={{ color: '#002E5D!important' }} onClick={() => closeDialog()}>
                        {dialogButtonGeneral.Cancel}
                    </Button>
                    <Button type="submit" variant="contained" className="primaryButton">
                        {dialogButtonGeneral.Confirm}
                    </Button>
                </DialogActions>
            </form>
        </>
    );
}