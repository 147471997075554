//REQUEST/RESPONSE HEADERS
export const RequestResponseHeaders = {
   HeaderToken: 'x-token',
   HeaderExpireToken: 'x-scadenzatoken',
   Authorization: 'Authorization'
}

//INTERNAL APP ROUTES
export const InternalAppRoutes = {
   Root: '/',
   Sale: '/sale',
   Settings: '/settings',
   Dashboard: '/dashboard',
   Guida: '/guida',
   Slides: '/slides',
   Unknown: '*'
}

//BREADCRUMS APP ROUTES
export const BreadcrumbsAppRoutes = {
   'sale': 'Sale',
   'settings' : 'Settings',
   'dashboard' : 'Dashboard',
   'guida': 'Guida',
}

//HUB ENDPOINT
export const HubEndpoint = {
   RiavviaMiniPc : 'RiavviaMiniPc'
}

//CLIENT LISTENER NAMES
export const ListenerSala = {
   AggiornaElencoSale: 'AggiornaElencoSale'
}

//MESSAGGI INFO/ERROR
export const Messaggi = {
   ComunicazioneServerAssente: 'Errore di comunicazione con il server.',
   AccessoNonConsentito: 'Accesso non consentito.'
}

//NOTIFICATION STATUS
export const NotificationStatus = {
   Error: 'error',
   Warning: 'warning',
   Info: 'info',
   Success: 'success'
}

//PALETTE
export const Palette = {
   PrimaryINAIL: '#002E5D',
   Error: '#dc3545'
}

//DIALOG
export const DialogPageTypes  = {
   users:"utenti",
   sale:"sale",
   guida:"guida"
}

//ENUM TIPI FILE 
export const FileTypes = {
   Image: "Immagine",
   Audio: "Audio",
   Video: "Video"
}

export const dialogAddSala = { DialogTitle: "Inserisci nuova sala" }
export const dialogEditSala = { DialogTitle: "Modifica sala" }
export const dialogViewSala = { DialogTitle: "Dettagli sala" }

export const dialogAddSlide = { DialogTitle: "Inserisci nuova slide" }
export const dialogEditSlide = { DialogTitle: "Modifica slide" }
export const dialogCloneSlide = { DialogTitle: "Clona guida" }
export const dialogDeleteSlide = { DialogTitle: "Cancella slide" }

//BUTTON
export const dialogButtonGeneral = { Confirm: "Salva", Cancel: "Annulla", Indietro: "Indietro" }
export const dialogButtonConfirm = { Confirm: "Conferma", Cancel: "Annulla", Indietro: "Indietro" }
export const dialogButtonRiepilogo = { Confirm: "Conferma", Chiudi: "Chiudi", Elimina: "Elimina", Disabilita: "Disabilita", Indietro: "Indietro" }

