import Notification from "../../components/Notification";
import SaleDatatable from "./SaleDataTable/SaleDatatable";
import { Breadcrumbs } from "@mui/material";
import { StyledBreadcrumb } from "../../components/Breadcrumb/StyledBreadcrumb";
import HomeIcon from '@mui/icons-material/Home';

const AnagraficaSale = () => {

    return (
        <>
            <Breadcrumbs mb={2} aria-label="breadcrumb">
                <StyledBreadcrumb
                    label="Sale"
                    icon={<HomeIcon fontSize="small" />
                    }
                />
            </Breadcrumbs>
            <SaleDatatable />
            <Notification></Notification>
        </>
    )
}

export default AnagraficaSale;