import { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { dialogAddSlide } from "../../../../consts/dictionary";
import FormAddSlide from "./FormAddSlide";


const DialogAddSlide = ({isOpen, idSala}) => {

    const [slide, setSlide] = useState({});
    const [isRenderingDialog, setIsRenderingDialog] = useState(false);

    return (
        <>
          {!isRenderingDialog && (
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ color: '#002E5D' }}>
                    {dialogAddSlide.DialogTitle}
                </DialogTitle>
                <DialogContent sx={{ paddingRight: '1px!important', paddingBottom: '1px!important' }}>
                    <FormAddSlide slide={slide} idSala={idSala}/>
                </DialogContent>
            </Dialog >
          )}
      </>
    );
}

export default DialogAddSlide;