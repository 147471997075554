import ButtonBoxSale from "../../pages/AnagraficaSale/SaleDataTable/ButtonBoxSale";
import ButtonBoxSlides from "../../pages/Guida/SlidesDataTable/ButtonBoxSlides";
import { Tooltip, CardMedia, Typography } from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import { FileTypes } from "../dictionary";
import ThumbAudio from '../../assets/Thumb_Audio.png';
import './DataTableData.css'

const minWidthColumn = 100;

export const SaleHeader = {
  initialFieldName: "Nome",
  initialFieldOrder: "asc",
  headers: [
    {
      field: "nomeSala",
      headerName: "Nome",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
    },
    {
      field: "isMiniPcAcceso",
      headerName: "Stato Mini PC",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <Tooltip title={x.value === true ? "Acceso" : "Spento"}>
            <CircleIcon sx={x.value === true ? { color: 'rgb(140 211 27)' } : { color: '#dc3545' }}></CircleIcon>
          </Tooltip>
        );
      },
      headerAlign: "left",
    },
    {
      field: "isSalaOccupata",
      headerName: "Stato Sala",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        return (
          <p> {x.value === true ? "Occupata" : "Libera"}</p>
        );
      },
      headerAlign: "left",
    },
    {
      field: "userAction",
      headerName: "Azioni",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      getActions: (cell) => {
        return [<ButtonBoxSale activeRow={cell.id} rowInfo={cell.row} />];
      },
      headerAlign: "right",
      align: "right",
    },
  ],
};

export const SlidesHeader = {
  headers: [
    {
      field: "posizione",
      headerName: "Posizione",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left"
    },
    {
      field: "thumbnail",
      headerName: "Anteprima",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      renderCell: (x) => {
        let thumbnail = x.row.tipoFile === 2 ? ThumbAudio : "data:image/png;base64, " + x.value;
        return (
          <CardMedia
            component="img"
            image={thumbnail}
            sx={{ width: 60, height: 30 }}
            alt="Slide content"
          />
        );
      },
      headerAlign: "left"
    },
    {
      field: "titolo",
      headerName: "Nome Slide",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left"
    },
    {
      field: "tipoFile",
      headerName: "Tipo File",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left",
      renderCell: (data) => {
        switch(data.value){
          case 1:
            return FileTypes.Image;

          case 2:
            return FileTypes.Audio;

          case 3:
            return FileTypes.Video;
        }
      }
    },
    {
      field: "estensione",
      headerName: "Estensione File",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      headerAlign: "left"
    },
    {
      field: "slideAction",
      headerName: "Azioni",
      type: "actions",
      flex: 1,
      resizable: false,
      sortable: false,
      minWidth: minWidthColumn,
      getActions: (cell) => {
        return [<ButtonBoxSlides activeRow={cell.id} rowInfo={cell.row} />];
      },
      headerAlign: "right",
      align: "right"
    }
  ]
};