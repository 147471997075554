import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';


const CDCard = styled(Card, {
    shouldForwardProp: (prop) => prop
})
(() => ({
    boxShadow: '0 1px 8px rgba(0, 0, 0, 0.25)'
}));

const CDCardHeader = styled(CardHeader, {
    shouldForwardProp: (prop) => prop
})
(() => ({
    '& .MuiTypography-root': {
        fontWeight: 'bold',
        color: '#004d99',
        fontSize: '15px'
    },
    backgroundColor: '#729bdd1a'
}));

const CDCardContent = styled(CardContent, {
    shouldForwardProp: (prop) => prop
})
(() => ({
    '&:last-child': {
        paddingBottom: 16
      }
}));

export { CDCard, CDCardHeader, CDCardContent };