import { createSlice } from "@reduxjs/toolkit";

const initialState = { open: false, message: "", status: "" };

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationOpen: (state, action) => {
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.status
      };
    },
    notificationClose: (state) => {
      return {
        ...state,
        open: false,
      };
    },
  },
});

export const OpenAndCloseNotification = (state) => (dispatch) => {
  dispatch(notificationOpen({ message: "Attenzione: la sessione scadrà tra 5 minuti. In caso di inattività verrete reindirizzati alla schermata di login.", status: "warning" }));
}

export const { notificationOpen, notificationClose } = notificationSlice.actions;

const { reducer } = notificationSlice;

export default reducer;
