import { Fab } from '@material-ui/core';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from "react-router-dom";
import { InternalAppRoutes } from '../../consts/dictionary';

const FloatingButtonSettings = () => {

    const navigate = useNavigate();

    return (
        <>
            <Fab onClick={() => { navigate(InternalAppRoutes.Settings) }} color="primary" aria-label="Impostazioni Globali" className='fixed-floating-bottom-left' >
                <SettingsIcon />
            </Fab>
        </>
    )
}

export default FloatingButtonSettings;