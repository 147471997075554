import { Typography, Grid, DialogTitle, DialogContent, Breadcrumbs } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Box } from "@mui/system";
import EditIcon from '@mui/icons-material/Edit';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SosIcon from '@mui/icons-material/Sos';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import {
    openDialogSala,
    setPageType,
} from "../../reducers/dialogSala-reducer";
import { DialogPageTypes, ListenerSala, Palette } from "../../consts/dictionary";
import SalaDialog from "../../components/Dialogs/SalaDialog/SalaDialog";
import { InternalAppRoutes } from "../../consts/dictionary";
import {
    useLocation,
} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { StyledBreadcrumb } from "../../components/Breadcrumb/StyledBreadcrumb";
import { useContext, useEffect, useState } from "react";
import ConnectionContext from "../../context/ConnectionContext";
import { getSalaById } from "../../consts/urlAction";
import axios from "axios";
import { notificationOpen } from "../../reducers/notification-reducer";
import { ReportOff } from "@mui/icons-material";
import { hideLoader, showLoader } from "../../reducers/appLoader-reducer";

const urlBase = window.API_URL;

const Dashboard = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const connection = useContext(ConnectionContext);
    const searchParams = new URLSearchParams(location.search);
    const idSala = searchParams.get('idSala');
    const [salaById, setSalaById] = useState();
    const [reloadDashboard, setReloadDashboard] = useState(true);
    const dialogState = useSelector((state) => state.dialogSala);

    const Item = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px'
    }));

    const ItemButton = styled(Box)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#002E5D',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'start',
        color: theme.palette.text.secondary,
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '4px',
        cursor: 'pointer',
        maxWidth: '100%'
    }));

    const handleGetSalaById = () => {
        dispatch(showLoader());
        (async () => {

            const { action, method, withDettaglio } = getSalaById;
            let url = urlBase + action + idSala + withDettaglio;

            try {
                const response = await axios({
                    method: method,
                    url,
                });

                if (response.data.ok) {
                    setSalaById(response.data.result);
                    dispatch(hideLoader());
                }
            } catch (error) {
                console.error(error);
                if (error.response) {
                    dispatch(
                        notificationOpen({
                            message: `${error.response.data.message}`,
                            status: "error",
                        })
                    );
                } else {
                    dispatch(
                        notificationOpen({
                            message: "Operazione fallita: Server non raggiungibile.",
                            status: "error",
                        })
                    );
                }
                dispatch(hideLoader());

            }
        })();
    }

    useEffect(() => {
        if (reloadDashboard) {
            handleGetSalaById();
            setReloadDashboard(false);
        }
    }, [reloadDashboard]);

    useEffect(() => { handleGetSalaById() }, [dialogState]);

    const handleDialogToEdit = ({ dialogType, rowInfo, isEdit }) => {
        dispatch(setPageType(DialogPageTypes.sale));
        dispatch(openDialogSala({ dialogType, rowInfo, isEdit }));
    };

    const handleRoute = ({ route, rowInfo }) => {
        navigate(route, {
            rowInfo: rowInfo,
        })
    };

    if (connection) {
        connection.on(ListenerSala.AggiornaElencoSale, (idUpdatedSala) => {
            if (idUpdatedSala === parseInt(idSala)) setReloadDashboard(true);
        });
    }

    if (salaById) {
        return (
            <>
                <Grid container>
                    <Breadcrumbs aria-label="breadcrumb">
                        <StyledBreadcrumb
                            label="Sale"
                            onClick={() => { navigate(InternalAppRoutes.Sale) }}
                            icon={<HomeIcon fontSize="small" />
                            }
                        />
                        <StyledBreadcrumb label="Dashboard" />
                    </Breadcrumbs>
                    <Grid item xs={12} sm={12} mt={2} className="centerVisualization">
                        <Grid container spacing={2}>
                            <Grid item container spacing={2} md={9}>
                                <Grid item xs={6} md={6}>
                                    <Item sx={{ height: '25vh' }}>
                                        <DialogTitle sx={{ color: '#002E5D' }}>
                                            Dettagli Sala
                                        </DialogTitle>
                                        <DialogContent>
                                            <Typography xs={3} md={3}>
                                                <b>Nome:</b> {salaById.nomeSala}
                                            </Typography>
                                            <Typography md={2}>
                                                <b>Indirizzo Ip Mini PC:</b> {salaById.indirizzoIp}
                                            </Typography>
                                            <Typography md={2}>
                                                <b>Account Microsoft:</b> {salaById.accountMicrosoft}
                                            </Typography>
                                            <Typography md={2}>
                                                <b>Nome sede:</b> {salaById.nomeSede}
                                            </Typography>
                                        </DialogContent>
                                    </Item>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Item sx={{ height: '25vh' }}>
                                        <DialogTitle sx={{ color: '#002E5D' }}>
                                            Stato
                                        </DialogTitle>
                                        <DialogContent sx={{ textAlign: 'start' }}>
                                            <Typography variant={"h3"} mt={2}>
                                                {salaById.isSalaOccupata ? "Occupata" : "Libera"}
                                            </Typography>
                                        </DialogContent>
                                    </Item>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                    <Item sx={{ height: '25vh' }}>
                                        <DialogTitle sx={{ color: '#002E5D' }}>
                                            Stato Mini PC
                                        </DialogTitle>
                                        <DialogContent sx={{ textAlign: 'start' }}>
                                            <Typography variant={"h3"} mt={2}>
                                                {salaById.isMiniPcAcceso ? "Acceso" : "Spento"}
                                            </Typography>
                                        </DialogContent>
                                    </Item>
                                </Grid>
                                <Grid item container columns={salaById.sosAttivo ? 18 : 12} spacing={2}>
                                    <Grid item xs={6} md={3}>
                                        <ItemButton className="button-zoom" onClick={() => handleDialogToEdit({ dialogType: "edit", rowInfo: salaById, isEdit: true })} sx={{ height: '20vh' }}>
                                            <DialogTitle sx={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                                Modifica dati
                                            </DialogTitle>
                                            <DialogContent sx={{ textAlign: 'center' }}>
                                                <EditIcon sx={{ fontSize: '4rem!important', color: '#fff', marginTop: '1rem' }} />
                                            </DialogContent>
                                        </ItemButton>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <ItemButton className="button-zoom" onClick={() => handleDialogToEdit({ dialogType: "resetPC", rowInfo: salaById, isEdit: false })} sx={{ height: '20vh' }}>
                                            <DialogTitle sx={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                                Riavvio Mini PC
                                            </DialogTitle>
                                            <DialogContent sx={{ textAlign: 'center' }}>
                                                <SyncProblemIcon sx={{ fontSize: '4rem!important', color: '#fff', marginTop: '1rem' }} />
                                            </DialogContent>
                                        </ItemButton>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <ItemButton className="button-zoom" onClick={() => handleDialogToEdit({ dialogType: "resetSala", rowInfo: salaById, isEdit: false })} sx={{ height: '20vh' }}>
                                            <DialogTitle sx={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                                Reset Postazione
                                            </DialogTitle>
                                            <DialogContent sx={{ textAlign: 'center' }}>
                                                <ResetTvIcon sx={{ fontSize: '4rem!important', color: '#fff', marginTop: '1rem' }} />
                                            </DialogContent>
                                        </ItemButton>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <ItemButton className="button-zoom" onClick={() => handleRoute({ route: InternalAppRoutes.Guida + "?idSala=" + salaById.idSala, rowInfo: salaById })} sx={{ height: '20vh' }}>
                                            <DialogTitle sx={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                                Gestione Guida
                                            </DialogTitle>
                                            <DialogContent sx={{ textAlign: 'center' }}>
                                                <SlideshowIcon sx={{ fontSize: '4rem!important', color: '#fff', marginTop: '1rem' }} />
                                            </DialogContent>
                                        </ItemButton>
                                    </Grid>
                                    {salaById.sosAttivo && <Grid item xs={6} md={3}>
                                        <ItemButton className="button-zoom" style={{ backgroundColor: Palette.Error }} onClick={() => handleDialogToEdit({ dialogType: "rispostaSOS", rowInfo: salaById, isEdit: false })} sx={{ height: '20vh' }}>
                                            <DialogTitle sx={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                                Risposta S.O.S.
                                            </DialogTitle>
                                            <DialogContent sx={{ textAlign: 'center' }}>
                                                <SosIcon sx={{ fontSize: '4rem!important', color: '#fff', marginTop: '1rem' }} />
                                            </DialogContent>
                                        </ItemButton>
                                    </Grid>}
                                    {salaById.sosAttivo && <Grid item xs={6} md={3}>
                                        <ItemButton className="button-zoom" style={{ backgroundColor: Palette.Error }} onClick={() => handleDialogToEdit({ dialogType: "chiudiSOS", rowInfo: salaById, isEdit: false })} sx={{ height: '20vh' }}>
                                            <DialogTitle sx={{ color: '#fff', whiteSpace: 'nowrap' }}>
                                                Chiudi S.O.S.
                                            </DialogTitle>
                                            <DialogContent sx={{ textAlign: 'center' }}>
                                                <ReportOff sx={{ fontSize: '4rem!important', color: '#fff', marginTop: '1rem' }} />
                                            </DialogContent>
                                        </ItemButton>
                                    </Grid>}
                                </Grid>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Item sx={{ height: '70vh' }}>
                                    <DialogTitle sx={{ color: '#002E5D' }}>
                                        Stato Servizi
                                    </DialogTitle>
                                    <DialogContent sx={{ textAlign: 'start' }}>
                                        {salaById.warning ?
                                            <ul>
                                                {salaById.warning.map((servizio) => {
                                                    return (
                                                        <Typography mt={2}>
                                                            <li><b>{servizio}</b></li>
                                                        </Typography>
                                                    )
                                                })}
                                            </ul>
                                            :
                                            <Typography mt={2}>
                                                <b>Tutti i servizi sono installati ed avviati correttamente.</b>
                                            </Typography>}
                                    </DialogContent>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <SalaDialog />
            </>
        );
    }
};

export default Dashboard;
