export function initialValuesSalaEdit(rowInfo) {
  return {
    idSala: rowInfo.idSala,
    nomeSala: rowInfo.nomeSala,
    isMiniPcAcceso: rowInfo.isMiniPcAcceso,
    isSalaOccupata: rowInfo.isSalaOccupata,
    statoWarning: rowInfo.statoWarning,
    accountMicrosoft: rowInfo.accountMicrosoft,
    indirizzoIp: rowInfo.indirizzoIp,
    nomeSede: rowInfo.nomeSede,
    indirizzoSede: rowInfo.indirizzoSede
  }
};

export function initialValuesSalaAdd() {
  return {
    nomeSala: "",
    isMiniPcAcceso: true,
    isSalaOccupata: true,
    statoWarning: false,
    accountMicrosoft: '',
    //indirizzoIp: '',
    nomeSede:'',
    indirizzoSede:''
  }
};

export function initialValuesSalaDelete(rowInfo) {
  return {
    idSala: rowInfo.idSala,
    nomeSala: rowInfo.nomeSala,
    isMiniPcAcceso: rowInfo.isMiniPcAcceso,
    isSalaOccupata: rowInfo.isSalaOccupata,
    statoWarning: rowInfo.statoWarning,
    accountMicrosoft: rowInfo.accountMicrosoft,
    indirizzoIp: rowInfo.indirizzoIp,
    nomeSede: rowInfo.nomeSede,
    indirizzoSede: rowInfo.indirizzoSede
  }
};
