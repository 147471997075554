import { createSlice } from '@reduxjs/toolkit';

const initialState = {isOpen:false , isEdit: true, dialogType:"", dialogPageType:"", rowInfo:-1};

const salaSlice = createSlice({
    name:'dialogSala',
    initialState,
    reducers: {
         openEmptyDialogSala: (state, payload) => { 
            return {...state, isOpen: true, dialogType:payload.payload};
         },
         setPageType: (state, payload) => { 
            return {...state, dialogPageType:payload.payload};
         },
         openDialogSala:(state,payload) => {
             return {...state, isOpen:true, dialogType:payload.payload.dialogType, rowInfo:payload.payload.rowInfo, isEdit:payload.payload.isEdit}
         },
         closeDialogSala: (state) => {
            return {...state, isOpen: false};
         }
    }
});

export const { openDialogSala, closeDialogSala, openEmptyDialogSala, setPageType } = salaSlice.actions;

const { reducer } = salaSlice;

export default reducer;