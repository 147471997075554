import { useEffect, useRef, useState } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  TextField,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { styled, alpha } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MapIcon from "@mui/icons-material/Map";
import useBackPathFromUrl from "../../hooks/useBackfromUrlPath";
import OverrideMaxWidth from "../../functions/overrideMaxWidth";

let buttonViewMap = false;

const paddingSideMobile = 33;
const paddingSideDesktop = 130;

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: "auto",
  color: "#004d99",
}));

const TableToolbar = ({
  startSearch,
  customButton = undefined,
  initialSearchText,
  enableSearch,
  enableBackOnToolbar = false,
  handleAdd,
  enableAdd
}) => {
  const [searchText, setSearchText] = useState(initialSearchText);
  const [delta, setDelta] = useState(
    window.innerWidth > 600 ? paddingSideDesktop : paddingSideMobile
  );
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleResizeWindow = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth > 600) {
      setDelta(paddingSideDesktop);
    } else {
      setDelta(paddingSideMobile);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  const path = useBackPathFromUrl();

  let string = OverrideMaxWidth(width, delta);


  return (
    <Grid container rowSpacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={9}
        sx={{ overflow: "auto", maxWidth: string }}
      >
        <Stack direction="row">
          {path != "" && enableBackOnToolbar && (
            <GridActionsCellItem
              icon={
                <Tooltip title="Indietro">
                  <ArrowBackIcon sx={{ fontSize: 35, color: '#002E5D' }} />
                </Tooltip>
              }
              label="Indietro"
              onClick={() => navigate(path)}
              sx={{marginRight: '2%!important'}}
            /> 
          )}
          {
            enableAdd && (
              <GridActionsCellItem
                icon={
                  <Tooltip title="Aggiungi">
                    <AddIcon sx={{ fontSize: '35px!important', color: '#002E5D' }} />
                  </Tooltip>
                }
                label="Aggiungi"
                onClick={handleAdd}
                sx={{marginRight: '1%!important'}}
              />          
            )}
          {customButton && customButton}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3}>
        {enableSearch && (
          <Search>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <TextField
                id="search-input"
                placeholder="Cerca..."
                inputProps={{ "aria-label": "search" }}
                value={searchText}
                variant="standard"
                fullWidth
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onBlur={() => {
                  startSearch(searchText);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    startSearch(searchText);
                  }
                }}
              />
              <Tooltip title="Cerca">
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "500px",
                    padding: "0",
                    minHeight: "35px",
                    minWidth: "35px",
                  }}
                  onClick={() => {
                    startSearch(searchText);
                  }}
                >
                  <SearchIcon sx={{ mr: 0, my: 0 }} />
                </Button>
              </Tooltip>
            </Box>
          </Search>
        )}
      </Grid>
    </Grid>
  );
};

export default TableToolbar;
