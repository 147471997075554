import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { notificationClose } from "../reducers/notification-reducer";
import { useDispatch, useSelector } from "react-redux";

const posizioneSnackbar = { vertical: "top", horizontal: "center" };

const Notification = () => {
  const state = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const { open } = state;

  const handleClose = (event, reason) => {
    if (reason === "clickaway" || event != null) {
      dispatch(notificationClose());
    }
    setTimeout(() => dispatch(notificationClose()), 3000);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={posizioneSnackbar}
        key={posizioneSnackbar.vertical + posizioneSnackbar.horizontal}
        sx={{height:'3rem', marginTop:'1rem'}}
      >
        <Alert onClose={handleClose} severity={state.severity} sx={{fontSize:'1rem'}}>
          {state.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Notification;
