import * as yup from "yup";

export const validationSchemaSalaAdd = yup.object({
    nomeSala: yup.string().required("Campo obbligatorio."),
    accountMicrosoft: yup.string().email("Inserire un email con formato corretto.").required("Campo obbligatorio."),
    //indirizzoIp: yup.string().matches(/^(\d{1,3}\.){3}\d{1,3}$/, 'Inserisci un indirizzo IP valido.').required("Campo obbligatorio."),
    nomeSede: yup.string().max(100, "Massimo 100 caratteri.").required("Campo obbligatorio."),
    indirizzoSede: yup.string().max(255, "Massimo 255 caratteri.").required("Campo obbligatorio.")
});

export const validationSchemaSalaEdit = yup.object({
    nomeSala: yup.string().required("Campo obbligatorio."),
    accountMicrosoft: yup.string().email("Inserire un email con formato corretto.").required("Campo obbligatorio."),
    //indirizzoIp: yup.string().matches(/^(\d{1,3}\.){3}\d{1,3}$/, 'Inserisci un indirizzo IP valido.').nullable(),
    nomeSede: yup.string().max(100, "Massimo 100 caratteri.").required("Campo obbligatorio."),
    indirizzoSede: yup.string().max(255, "Massimo 255 caratteri.").required("Campo obbligatorio.")
});


