import { Box } from "@mui/material";
import Navbar from "./Navbar/Navbar";
import FloatingButtonSettings from "../components/FloatingButtonSettings/FloatingButtonSettings";
import "../index.css";

const Layout = ({ children, isLoginPage, isSettingsPage }) => {

    return (
        <>
            <Box>
                {!isLoginPage && <Navbar />}
                <Box component="main" className="backgroundImage">
                    <Box mt={10} sx={{ paddingX: '3rem', width: '100%' }}> {children} </Box>
                </Box>
            </Box>
            {(!isLoginPage && !isSettingsPage) && <FloatingButtonSettings></FloatingButtonSettings>}
        </>
    );
};

export default Layout;
