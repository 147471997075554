import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Tooltip, Grid, Typography } from "@mui/material";
import { dialogButtonConfirm } from "../../../../consts/dictionary";
import { closeDialogMoveSlide } from "../../../../reducers/dialogSlide-reducer";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { reloadingDataTableSlides } from "../../../../reducers/dataTableSlides-reducer";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import handlerError from "../../../../helpers/handlerError";
import { moveSlide } from "../../../../consts/urlAction";

const urlBase = window.API_URL;

const DialogMoveSlide = ({ isOpen, idSala, setSelectionModel }) => {
    const dispatch = useDispatch();
    const dialogState = useSelector((state) => state.dialogSlide);
    const { slideInfo, slides, maxPosition } = dialogState;
    const [position, setPosition] = useState(slideInfo.posizione ? slideInfo.posizione : 1);


    const handlePositionChange = (event) => {
        let newPosition = Number(event.target.value);

        if (newPosition > maxPosition) {
            newPosition = maxPosition;
            dispatch(
                notificationOpen({
                    message: "La posizione non può essere maggiore di " + maxPosition,
                    status: "warning"
                })
            );
        }
        if (newPosition < 1) {
            newPosition = 1;
            dispatch(
                notificationOpen({
                    message: "La posizione non può essere minore di 1",
                    status: "warning"
                })
            );
        }

        setPosition(newPosition);
    };

    const handleIncrement = () => {
        if (Number(position) >= maxPosition) {
            dispatch(
                notificationOpen({
                    message: "La posizione non può essere maggiore di " + maxPosition,
                    status: "warning"
                })
            );
            return;
        }

        let newPosition = position + 1;
        setPosition(Number(newPosition));
        callMoveSlide(newPosition);
    };

    const handleDecrement = () => {
        if (Number(position) === 1) {
            dispatch(
                notificationOpen({
                    message: "La posizione non può essere minore di 1",
                    status: "warning"
                })
            );
            return;
        }

        let newPosition = position - 1;
        setPosition(Number(newPosition));
        callMoveSlide(newPosition);
    };

    const handleClose = () => {
        setSelectionModel([]);
        dispatch(closeDialogMoveSlide());
    }

    const handleSubmit = () => {
        if (Number(position) < 1 || Number(position) > maxPosition) return;
        callMoveSlide(position);
        handleClose();
    };

    const callMoveSlide = (newPosition) => {
        const requestData = {
            idSala: idSala,
            idSlide: slideInfo.idSlide,
            posizione: newPosition
        };

        (async () => {
            let action, method, url;
            action = moveSlide.action;
            method = moveSlide.method;
            url = urlBase + action;

            try {
                const response = await axios({
                    method: method,
                    url,
                    data: requestData
                });

                if (response.data.ok) {
                    dispatch(reloadingDataTableSlides());
                }
                dispatch(
                    notificationOpen({
                        message: response.data.message,
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
        })();
    }

    useEffect(() => {
        setPosition(slideInfo.posizione ? slideInfo.posizione : 1);
    }, [dialogState]);

    return (
        <>
            <Dialog
                open={isOpen}
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle >
                    Sposta slide <b>{slideInfo.titolo}</b>
                </DialogTitle>
                <DialogContent>
                    <Grid item columns={12}>
                        <Typography className="css-1wc848c-MuiFormHelperText-root">
                            Utilizzare le frecce per spostare di una posizione la slide oppure inserire la posizione preferita nel campo di input numerico.
                        </Typography>
                    </Grid>
                    <Grid container columns={12} columnSpacing={5} mt={3} sx={{ justifyContent: 'center' }}>
                        {/*                         <Grid item sm={2} md={2}> */}
                        <Tooltip title="Sposta su"><ArrowUpwardIcon className="moveSlideButton" onClick={handleDecrement} /></Tooltip>
                        {/*                         </Grid>
 */}                        <Grid item sm={3} md={3}>
                            <TextField
                                fullWidth
                                item
                                id="posizione"
                                label="Posizione attuale"
                                type="tel"
                                variant="standard"
                                value={position}
                                onChange={handlePositionChange}
                                InputProps={{ inputProps: { min: 1, max: maxPosition } }}
                            />
                        </Grid>
                        <Grid item sm={2} md={2}>
                            <Tooltip title="Sposta giù"><ArrowDownwardIcon className="moveSlideButton" onClick={handleIncrement} /></Tooltip>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className="blueText" onClick={handleClose}>
                        {dialogButtonConfirm.Cancel}
                    </Button>
                    <Button
                        className="primaryButton"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        {dialogButtonConfirm.Confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DialogMoveSlide;