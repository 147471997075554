import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, FormControl, Grid, TextField, Typography } from "@mui/material";
import { dialogButtonGeneral } from "../../../../consts/dictionary";
import { addSlide } from "../../../../consts/urlAction";
import { initialValuesSlideAdd } from "../../../../consts/formik/Slide/initialValuesSlide";
import { validationSchemaSlideAdd } from "../../../../consts/formik/Slide/validationSchemaSlide";
import { closeDialogSlide } from "../../../../reducers/dialogSlide-reducer";
import { reloadingDataTableSlides } from "../../../../reducers/dataTableSlides-reducer";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { hideLoader, showLoader } from "../../../../reducers/appLoader-reducer";
import handlerError from "../../../../helpers/handlerError";
import "../../../../index.css";

const allowedFileExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.mp4', '.ogg', '.webm', '.pdf'];
const allowedFileExtensionsTip = ['.jpg', ' .jpeg', ' .png', ' .gif', ' .mp4', ' .ogg', ' .webm', ' .pdf'];
const urlBase = window.API_URL;

const FormAddSlide = ({ slide, idSala }) => {

    const dispatch = useDispatch();
    const [changedRow, setChangedRow] = useState();
    const [thumbnailDisabled, setThumbnailDisabled] = useState(true);
    let initialValues = initialValuesSlideAdd(slide);
    let validationSchema = validationSchemaSlideAdd;

    const handleChangeFile = (event) => {
        if (event?.currentTarget?.files[0]) {
            if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
                formik.setFieldValue("file", event.currentTarget.files[0]);
                setThumbnailDisabled(true);
            } else if (event?.currentTarget?.files[0]?.type?.startsWith("video")) {
                formik.setFieldValue("file", event.currentTarget.files[0]);
                setThumbnailDisabled(false);
            } else if (event?.currentTarget?.files[0]?.type?.startsWith("audio")) {
                formik.setFieldValue("file", event.currentTarget.files[0]);
                setThumbnailDisabled(true);
            } else {
                formik.setFieldError("file", "Il tipo di file non è consentito.");
                setThumbnailDisabled(true);
            }
        }
    }

    const handleChangeThumbnail = (event) => {
        if (event?.currentTarget?.files[0]) {
            if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
                formik.setFieldValue("thumbnail", event.currentTarget.files[0]);
            } else {
                formik.setFieldError("thumbnail", "Il tipo di file non è consentito.");
            }
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (thumbnailDisabled === false && (values.thumbnail === null || !values.hasOwnProperty('thumbnail'))) {
                formik.setFieldError("thumbnail", "Campo Obbligatorio se si carica un file di tipo video.");
            } else {
                setChangedRow({
                    IdSala: idSala,
                    NomeSlide: values.nome,
                    File: values.file,
                    Thumbnail: values.thumbnail
                });
            }
        },
    });

    const closeDialog = () => {
        formik.resetForm(initialValues);
        dispatch(closeDialogSlide());
    };

    useEffect(() => {
        if (changedRow != null && formik.isValid) {
            (async () => {
                let action, method, url;
                action = addSlide.action;
                method = addSlide.method;
                url = urlBase + action;

                let formData = new FormData();
                Object.keys(changedRow).map((key) => {
                    formData.append(`${key}`, changedRow[key]);
                });

                dispatch(showLoader());
                try {
                    const response = await axios({
                        method: method,
                        url,
                        data: formData,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    });

                    if (response.data.ok) {
                        formik.resetForm(initialValues);
                        dispatch(reloadingDataTableSlides());
                        dispatch(closeDialogSlide());
                        dispatch(hideLoader());
                    }
                    dispatch(
                        notificationOpen({
                            message: response.data.message,
                            status: "success",
                        })
                    );
                } catch (error) {
                    console.error(error);
                    const notificationError = handlerError(error);
                    dispatch(notificationOpen(notificationError));

                    if (error?.response?.data?.errors != undefined) {
                        let keys = Object.keys(error?.response?.data?.errors);
                        keys.map((key) => {
                            formik.setFieldError(key, error?.response?.data?.errors[key]);
                        });
                    }
                }
                dispatch(hideLoader());
            })();
        }
    }, [changedRow]);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormControl fullWidth >
                    <Grid container rowSpacing={1} columnSpacing={2} sx={{ paddingRight: '24px!important' }}>
                        <Grid item xs={12} sm={12} mb={1}>
                            <TextField
                                fullWidth
                                margin="dense"
                                id="nome"
                                label="Nome"
                                type="text"
                                variant="standard"
                                value={formik.values.nome}
                                onChange={formik.handleChange("nome")}
                                error={formik.touched.nome && Boolean(formik.errors.nome)}
                                helperText={formik.touched.nome && formik.errors.nome}
                                inputProps={{
                                    maxLength: 150
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={"blueText"}>
                                File
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} mb={1}>
                            <TextField
                                margin="dense"
                                id="file"
                                label=""
                                type="file"
                                fullWidth
                                variant="standard"
                                onChange={(event) => handleChangeFile(event)}
                                error={formik.errors.file && Boolean(formik.errors.file)}
                                helperText={formik.errors.file && formik.errors.file}
                                InputProps={{
                                    inputProps: {
                                        accept: allowedFileExtensions.join(','),
                                    },
                                }}
                            />
                            <Typography className={"blueText"} sx={{fontSize:'0.8rem'}}>
                                {allowedFileExtensionsTip}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={"blueText"}>
                                Thumbnail
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                margin="dense"
                                id="thumbnail"
                                label=""
                                type="file"
                                fullWidth
                                disabled={thumbnailDisabled}
                                variant="standard"
                                onChange={(event) => handleChangeThumbnail(event)}
                                error={formik.errors.thumbnail && Boolean(formik.errors.thumbnail)}
                                helperText={formik.errors.thumbnail && formik.errors.thumbnail}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
                <DialogActions sx={{ mt: 3 }}>
                    <Button sx={{ color: '#002E5D!important' }} onClick={() => closeDialog()}>
                        {dialogButtonGeneral.Cancel}
                    </Button>
                    <Button type="submit" variant="contained" className="primaryButton">
                        {dialogButtonGeneral.Confirm}
                    </Button>
                </DialogActions>
            </form>
        </>
    );
}

export default FormAddSlide;