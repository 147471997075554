import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material";
import { dialogButtonConfirm, dialogDeleteSlide } from "../../../../consts/dictionary";
import handlerError from "../../../../helpers/handlerError";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { closeDialogSlide } from "../../../../reducers/dialogSlide-reducer";
import { reloadingDataTableSlides } from "../../../../reducers/dataTableSlides-reducer";
import { hideLoader, showLoader } from "../../../../reducers/appLoader-reducer";
import { deleteSlide } from "../../../../consts/urlAction";

const urlBase = window.API_URL;

const DialogDeleteSlide = ({isOpen, rowInfo, idSala}) => {

    const dispatch = useDispatch();

    const handleDeleteSlide = (rowInfo) => {
        let idSlide = rowInfo.idSlide;
        let idSalaSlide = parseInt(idSala);

        (async () => {
            let action, method, url;
            action = deleteSlide.action;
            method = deleteSlide.method;  
            url = urlBase + action;
    
            dispatch(showLoader());
            try {
                const response = await axios({
                    method: method,
                    url,
                    data: {
                        idSlide: idSlide,
                        idSala: idSalaSlide
                    }
                });
        
                if (response.data.ok) {
                    dispatch(reloadingDataTableSlides());
                    dispatch(closeDialogSlide());
                }
                dispatch(
                    notificationOpen({
                        message: response.data.message,
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
                dispatch(hideLoader());
            }           
        })();

        try {
            dispatch(reloadingDataTableSlides());
            dispatch(closeDialogSlide());
            dispatch(
                notificationOpen({
                    message: "Slide cancellata con successo",
                    status: "success"
                })
            );
        } catch (error) {
            console.error(error);
            const notificationError = handlerError(error);
            dispatch(notificationOpen(notificationError));
            dispatch(hideLoader());
        }
    }

    return (
        <>
            <Dialog
                open={isOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={"sm"}
            >
                <DialogTitle sx={{ color: '#002E5D' }}>
                    {dialogDeleteSlide.DialogTitle}
                </DialogTitle>
                <DialogContent className="border-Content-dialog">
                    <DialogContentText id="alert-dialog-description">
                        Cancellare la slide <b>{rowInfo.titolo}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: '#002E5D!important' }} onClick={() => dispatch(closeDialogSlide())}>
                        {dialogButtonConfirm.Cancel}
                    </Button>
                    <Button
                        className="primaryButton"
                        variant="contained"
                        onClick={() => handleDeleteSlide(rowInfo)}
                    >
                        {dialogButtonConfirm.Confirm}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DialogDeleteSlide;