import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { closeDialogSlide } from "../../../reducers/dialogSlide-reducer";
import DialogAddSlide from "./DialogAddSlide/DialogAddSlide";
import DialogEditSlide from "./DialogEditSlide/DialogEditSlide";
import DialogDeleteSlide from "./DialogDeleteSlide/DialogDeleteSlide";
import DialogCloneSlides from "./DialogCloneSlides/DialogCloneSlides";
import DialogMoveSlide from "./DialogMoveSlide/DialogMoveSlide";

const SlideDialog = ({idSala, setSelectionModel}) => {

    const dispatch = useDispatch();
    const dialogState = useSelector((state) => state.dialogSlide);
    const backButtonState = useSelector((state) => state.dialogBackButton);
    const { rowInfo } = dialogState;

    //Gestisce il cambio di Datatable con la Dialog Aperta, la chiude e il dialogPageType impedisce di far partire la chiamata
    useEffect(() => {
        dispatch(closeDialogSlide());
    }, [window.onhashchange]);

    useEffect(() => {
        dispatch(closeDialogSlide());
    }, [backButtonState]);

    
    return (
        <>
            {dialogState.dialogType === "delete" && (
                <DialogDeleteSlide isOpen={dialogState.isOpen} rowInfo={rowInfo} idSala={idSala}/>
            )}
            {(dialogState.dialogType === "edit") && (
                <DialogEditSlide isOpen={dialogState.isOpen} rowInfo={rowInfo} idSala={idSala}/>
            )}
            {dialogState.dialogType === "add" && (
                <DialogAddSlide isOpen={dialogState.isOpen} idSala={idSala}/>
            )}
            {dialogState.dialogType === "clone" && (
                <DialogCloneSlides isOpen={dialogState.isOpen} idSala={idSala}/>
            )}
            {dialogState.dialogType === "move" && (
                <DialogMoveSlide isOpen={dialogState.isOpen} idSala={idSala} setSelectionModel={setSelectionModel}/>
            )}
        </>
    );
};

export default SlideDialog;
