import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, FormControl, Grid, TextField, Autocomplete } from "@mui/material";
import { dialogButtonGeneral } from "../../../../consts/dictionary";
import { getElencoSale } from "../../../../consts/urlAction";
import { cloneSlide } from "../../../../consts/urlAction";
import { closeDialogSlide } from "../../../../reducers/dialogSlide-reducer";
import { reloadingDataTableSlides } from "../../../../reducers/dataTableSlides-reducer";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { hideLoader, showLoader } from "../../../../reducers/appLoader-reducer";
import handlerError from "../../../../helpers/handlerError";
import "../../../../index.css";

const urlBase = window.API_URL;

const FormCloneSlides = ({idSala}) => {

    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [elencoSale, setElencoSale] = useState([]);
    const [selectedSala, setSelectedSala] = useState({});


    const closeDialog = () => {
        resetForm();
        dispatch(closeDialogSlide());
    };

    const resetForm = () => {
        setIsError(false);
        setErrorMessage("");
        setSelectedSala({});
    }

    const handleSubmit = () => {
        if(selectedSala?.idSala === undefined){
            setIsError(true);
            setErrorMessage("Campo Obbligatorio.");
            return;
        }

        (async () => {
            let action, method, url;
            action = cloneSlide.action;
            method = cloneSlide.method;  
            url = urlBase + action;
    
            dispatch(showLoader());
            try {
                const response = await axios({
                    method: method,
                    url,
                    data: {
                        idSalaFrom: selectedSala?.idSala,
                        idSalaTo: parseInt(idSala)
                    }
                });
        
                if (response.data.ok) {
                    resetForm();
                    dispatch(reloadingDataTableSlides());
                    dispatch(closeDialogSlide());
                    dispatch(hideLoader());
                }
                dispatch(
                    notificationOpen({
                        message: response.data.message,
                        status: "success"
                    })
                );
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
            dispatch(hideLoader());
        })();
    };

    useEffect(() => {
        setIsError(false);
        setErrorMessage("");
    },[selectedSala]);

    useEffect(() => {
        (async () => {
            let action, method, url, queryString;
            action = getElencoSale.action;
            method = getElencoSale.method; 
            queryString = "?idSala=" + idSala; 
            url = urlBase + action + queryString;

            try {
                const response = await axios({
                    method: method,
                    url
                });

                if (response.data.ok) {
                    setElencoSale(response.data.result);
                }
            } catch (error) {
                console.error(error);
                const notificationError = handlerError(error);
                dispatch(notificationOpen(notificationError));
            }
        })();
    },[]);


    return (
        <>
            <FormControl fullWidth>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Autocomplete
                        id="idSalaTo"
                        fullWidth
                        clearOnEscape                           
                        options={elencoSale}
                        value={selectedSala}
                        isOptionEqualToValue={(option, value) => option.idSala === value.idSala}
                        getOptionLabel={option => option.nomeSala ? option.nomeSala : ""}
                        onChange={(event, value) => setSelectedSala(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Copia slide dalla sala"
                                variant="standard"
                                fullWidth
                                error={isError}
                                helperText={errorMessage}
                            />
                        )}
                        sx={{ width: 350, marginLeft: "1%", marginTop: "1%" }}
                    />
                </Grid>
            </FormControl>
            <DialogActions sx={{ mt: 3 }}>
                <Button sx={{ color: '#002E5D!important' }} onClick={() => closeDialog()}>
                    {dialogButtonGeneral.Cancel}
                </Button>
                <Button variant="contained" className="primaryButton" onClick={() => handleSubmit()}>
                    {dialogButtonGeneral.Confirm}
                </Button>
            </DialogActions>
        </>
    );
}

export default FormCloneSlides;