import axios from "axios";
import { useFormik } from "formik";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, DialogActions, FormControl, FormControlLabel, RadioGroup, Radio, Grid, TextField, Typography } from "@mui/material";
import { dialogButtonGeneral } from "../../../../consts/dictionary";
import { editSlide } from "../../../../consts/urlAction";
import { initialValuesSlideEdit } from "../../../../consts/formik/Slide/initialValuesSlide";
import { validationSchemaSlideEdit } from "../../../../consts/formik/Slide/validationSchemaSlide";
import handlerError from "../../../../helpers/handlerError";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { reloadingDataTableSlides } from "../../../../reducers/dataTableSlides-reducer";
import { closeDialogSlide } from "../../../../reducers/dialogSlide-reducer";
import { hideLoader, showLoader } from "../../../../reducers/appLoader-reducer";
import "../../../../index.css";

const urlBase = window.API_URL;

const FormEditSlide = ({ rowInfo, idSala}) => {

    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const thumbnailInputRef = useRef(null);
    let initialValues = initialValuesSlideEdit(rowInfo);
    let validationSchema = validationSchemaSlideEdit;
    const [changedRow, setChangedRow] = useState();
    const [fileDisabled, setFileDisabled] = useState(true);
    const [thumbnailDisabled, setThumbnailDisabled] = useState(true);
    const [keepModifyButton, setKeepModifyButton] = useState(initialValues.abilitaModificaSorgente);

    const handleChangeFile = (event) => {
      if(event?.currentTarget?.files[0]){
          if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
              formik.setFieldValue("file", event.currentTarget.files[0]);
              setThumbnailDisabled(true);
          } else if(event?.currentTarget?.files[0]?.type?.startsWith("video")){
              formik.setFieldValue("file", event.currentTarget.files[0]);
              setThumbnailDisabled(false);
          } else if(event?.currentTarget?.files[0]?.type?.startsWith("audio")){
              formik.setFieldValue("file", event.currentTarget.files[0]);
              setThumbnailDisabled(true);
          }else {
              formik.setFieldError("file", "Il tipo di file non è consentito.");
              setThumbnailDisabled(true);
          }
      }
    }

    const handleChangeThumbnail = (event) => {
        if(event?.currentTarget?.files[0]){
            if (event?.currentTarget?.files[0]?.type?.startsWith("image")) {
                formik.setFieldValue("thumbnail", event.currentTarget.files[0]);
            } else {
                formik.setFieldError("thumbnail", "Il tipo di file non è consentito.");
            }
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {    
            if(fileDisabled === false && (values.file === null || values.file === "" || !values.hasOwnProperty('file'))){
                formik.setFieldError("file", "Campo Obbligatorio.");
            }

            if(thumbnailDisabled === false && (values.thumbnail === null || values.thumbnail === "" || !values.hasOwnProperty('thumbnail'))){
                formik.setFieldError("thumbnail", "Campo Obbligatorio se si carica un file di tipo video.");
            } else {
                setChangedRow({
                    IdSala: idSala,
                    IdSlide: values.idSlide,
                    NomeSlide: values.nome,
                    File: values.file,
                    Thumbnail: values.thumbnail,
                    AbilitaModificaSorgente: keepModifyButton
                });
            }
        },
    });

    useEffect(() => {
        if (changedRow != null && formik.isValid) {
          (async () => {
            let action, method, url;
            action = editSlide.action;
            method = editSlide.method;  
            url = urlBase + action;

            let formData = new FormData();
            Object.keys(changedRow).map((key) => {
                formData.append(`${key}`, changedRow[key]);
            });
    
            dispatch(showLoader());
            try {
              const response = await axios({
                method: method,
                url,
                data: formData,
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              });
    
              if (response.data.ok) {
                formik.resetForm(initialValues);
                dispatch(reloadingDataTableSlides());
                dispatch(closeDialogSlide());
                dispatch(hideLoader());
              }
              dispatch(
                notificationOpen({
                  message: response.data.message,
                  status: "success",
                })
              );
            } catch (error) {
              console.error(error);
              const notificationError = handlerError(error);
              dispatch(notificationOpen(notificationError));
              dispatch(hideLoader());
    
              if (error?.response?.data?.errors !== undefined) {
                let keys = Object.keys(error?.response?.data?.errors);
                keys.map((key) => {
                  formik.setFieldError(key, error?.response?.data?.errors[key]);
                });
              }
            }
           dispatch(hideLoader());
          })();
        }
    }, [changedRow]);

    useEffect(() => {
      if(keepModifyButton == false || keepModifyButton == "false"){
        setFileDisabled(true);
        setThumbnailDisabled(true);
        formik.values.file = "";
        formik.values.thumbnail = "";
        formik.setErrors({});
        fileInputRef.current.value = "";
        thumbnailInputRef.current.value = "";
      } else {
        setFileDisabled(false);
      }
    }, [keepModifyButton]);

    const closeDialog = () => {
        formik.resetForm(initialValues);
        dispatch(closeDialogSlide());
    };

    const handleRadioChange = (event) => {
        setKeepModifyButton(event.target.value);
    };

    return (
        <>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth >
                    <Grid container rowSpacing={1} columnSpacing={2} sx={{ paddingRight: '24px!important' }}>
                        <TextField
                            hidden
                            id="idSlide"
                            type="hidden"
                            value={formik.values.idSlide}
                        />
                        <Grid item xs={12} sm={12} mb={1}>
                            <TextField
                                id="nome"
                                label="Nome"
                                type="text"
                                margin="dense"
                                variant="standard"
                                fullWidth
                                value={formik.values.nome}
                                onChange={formik.handleChange("nome")}
                                error={formik.touched.nome && Boolean(formik.errors.nome)}
                                helperText={formik.touched.nome && formik.errors.nome}
                                inputProps={{
                                    maxLength: 150
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={"blueText"}>
                            File
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} mb={1}>
                            <TextField
                                id="file"
                                label=""
                                type="file"
                                margin="dense"
                                variant="standard"
                                fullWidth
                                disabled={fileDisabled}
                                inputRef={fileInputRef}
                                onChange={(event) => handleChangeFile(event)}
                                error={formik.touched.file && Boolean(formik.errors.file)}
                                helperText={ formik.touched.file && formik.errors.file }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography className={"blueText"}>
                            Thumbnail
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} mb={1}>
                            <TextField
                                id="thumbnail"
                                label=""
                                type="file"
                                margin="dense"
                                variant="standard"
                                fullWidth
                                disabled={thumbnailDisabled}
                                inputRef={thumbnailInputRef}
                                onChange={(event) => handleChangeThumbnail(event)}
                                error={formik.touched.thumbnail && Boolean(formik.errors.thumbnail)}
                                helperText={ formik.touched.thumbnail && formik.errors.thumbnail } 
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <RadioGroup
                              row
                              aria-label="recoveryEnabled"
                              name="row-radio-group-enabled"
                              onChange={(event) => {
                                handleRadioChange(event);
                              }}
                              value={keepModifyButton}
                              sx={{marginLeft:"2%"}}
                            >
                              <FormControlLabel
                                value="false"
                                control={<Radio
                                sx={{
                                  '&, &.Mui-checked': {
                                    color: '#002E5D',
                                  },
                                }}/>}
                                label="Mantieni"
                              />
                              <FormControlLabel
                                value="true"
                                control={<Radio
                                sx={{
                                  '&, &.Mui-checked': {
                                    color: '#002E5D',
                                  },
                                }}/>}
                                label="Modifica"
                              />
                            </RadioGroup>
                          </Grid>
                    </Grid>
                </FormControl>
                <DialogActions sx={{ mt: 3 }}>
                    <Button sx={{ color: '#002E5D!important' }} onClick={() => closeDialog()}>
                        {dialogButtonGeneral.Cancel}
                    </Button>
                    <Button type="submit" variant="contained" className="primaryButton">
                        {dialogButtonGeneral.Confirm}
                    </Button>
                </DialogActions>
            </form>
        </>
    );
}

export default FormEditSlide;