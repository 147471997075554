import React from "react";
import { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { dialogEditSlide } from "../../../../consts/dictionary";
import FormEditSlide from "./FormEditSlide";


const DialogEditSlide = ({ isOpen, rowInfo, idSala }) => {

  const [isRenderingDialog, setIsRenderingDialog] = useState(false);

  return (
    <>
      {!isRenderingDialog && (
        <Dialog
          open={isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle sx={{ color: '#002E5D' }}>
            {dialogEditSlide.DialogTitle}
          </DialogTitle>
          <DialogContent sx={{ paddingRight: '1px!important', paddingBottom: '1px!important' }}>
            <FormEditSlide rowInfo={rowInfo} idSala={idSala}/>
          </DialogContent>
        </Dialog >
      )}
    </>
  );
}

export default DialogEditSlide;
