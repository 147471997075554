import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const dataTableSlides = createSlice({
    name:'dataTableSlides',
    initialState,
    reducers: {
        reloadingDataTableSlides: (state) => {
            return !state;
         }
    }
})

export const { reloadingDataTableSlides } = dataTableSlides.actions;

const { reducer } = dataTableSlides;

export default reducer;