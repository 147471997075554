import { useDispatch } from "react-redux";
import { openDialogSlide, setPageType } from "../../../reducers/dialogSlide-reducer";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DialogPageTypes } from '../../../consts/dictionary';
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

  
const ButtonBoxSlides = ({ rowId, rowInfo }) => {

    const dispatch = useDispatch();
  
    const handleDialog = ({ dialogType, rowInfo, isEdit }) => {
      dispatch(setPageType(DialogPageTypes.guida));
      dispatch(openDialogSlide({ dialogType, rowInfo, isEdit }));
    };
    
    return (
      <>      
        <GridActionsCellItem
          icon={
            <Tooltip title="Modifica">
              <EditIcon sx={{ fontSize: 25, color: '#002E5D' }} />
            </Tooltip>
          }
          label="Modifica"
          onClick={() => handleDialog({ dialogType: "edit", rowInfo, isEdit: true })}
        />
        <GridActionsCellItem
          icon={
            <Tooltip title="Cancella">
              <DeleteIcon sx={{ fontSize: 25, color: '#002E5D' }} />
            </Tooltip>
          }
          label="Cancella"
          onClick={() => handleDialog({ dialogType: "delete", rowInfo, idEdit: false })}
        />
      </>
    );
  };
  
  export default ButtonBoxSlides;
  