import { AppBar, MenuItem, Toolbar } from "@mui/material";
import logo from '../../assets/LogoInail_small_bianco.png';
import "../../index.css";
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import IconButton from "@mui/material/IconButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useContext, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AccountInfo from "../../components/AccountInfo/AccountInfo";
import { InternalAppRoutes } from '../../consts/dictionary';
import { useDispatch } from "react-redux";
import { logoutUser } from "../../reducers/userData-reducer";
import ConnectionContext from "../../context/ConnectionContext";

const Navbar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const connection = useContext(ConnectionContext);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        dispatch(logoutUser());
        if (connection) connection.stop();
        navigate("/");
    };

    return (
        <>
            <AppBar className="appBarStyle">
                <Toolbar className="centerVisualization blueBackground toolbarCointainer">
                    <MenuItem key="Titolo" className="mainLogo" disableRipple>
                        <img src={logo} alt="" className="appBarLogoSize" onClick={() => {
                            navigate(InternalAppRoutes.Sale);
                        }} />
                    </MenuItem>
                    <Tooltip title="Account settings">
                        <IconButton
                            color="inherit"
                            id="basic-button"
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            className="accountMenuRight"
                        >
                            <ManageAccountsIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <AccountInfo />
                        <MenuItem className="logoutStyle" onClick={() => { handleLogout() }}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
