import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Interceptor, { checkToken } from "./helpers/Interceptor";
import AnagraficaSale from './pages/AnagraficaSale/AnagraficaSale';
import Notification from './components/Notification';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Login from './pages/Login/Login';
import Layout from './layout/Layout';
import { theme } from './consts/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import Settings from './pages/Settings/Settings';
import { InternalAppRoutes } from './consts/dictionary';
import './App.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Guida from './pages/Guida/Guida';
import Loader from './components/Loader'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ConnectionContext from './context/ConnectionContext';
import { logoutUser, refreshToken } from './reducers/userData-reducer';
import store from './store';

const hubUrl = window.HUB_URL;
function App() {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [isSettingsPage, setIsSettingsPage] = useState(false);
  const [connection, setConnection] = useState();
  const state = useSelector((state) => state.userDate);
  const userData = localStorage.getItem('cmsroomsmeetinguser');

  useEffect(() => {
    if (userData) {
      const user = JSON.parse(userData);
      if (checkToken(user.scadenzaToken)) {
        dispatch(refreshToken(user, navigate));
      } else {
        dispatch(logoutUser());
      }
    }
  }, [])

  //recupera dal localstorage e popola il context(redux) con i dati degli utenti.
  if (JSON.stringify(state) === '{}' && location.pathname !== '/') {
    const objUserDate = JSON.parse(userData)
    dispatch(refreshToken(objUserDate, navigate));
  }

  useEffect(() => { if (location.pathname !== '/' && !connection?.connectionId && userData) startConnection() }, [location])

  const startConnection = async () => {
    const state = store.getState();
    const { token } = state.userData;

    try {
      const connection = new HubConnectionBuilder()
        .withUrl(hubUrl, { accessTokenFactory: () => token })
        .configureLogging(LogLevel.Information)
        .build();

      connection.serverTimeoutInMilliseconds = 300000;

      connection.onreconnecting(error => {
        console.log(`Connessione persa a causa dell'errore:"${error}". Tentativo di riconnessione.`)
      });
      connection.onreconnected(connectionId => {
        console.log(`Connessione ristabilita. Connesso con connectionId "${connectionId}".`)
      });

      await connection.start();
      setConnection(connection);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {

    switch (location.pathname) {
      case InternalAppRoutes.Root:
        setIsLoginPage(true);
        setIsSettingsPage(false);
        break;

      case InternalAppRoutes.Settings:
        setIsLoginPage(false);
        setIsSettingsPage(true);
        break;

      default:
        setIsLoginPage(false);
        setIsSettingsPage(false);
        break;
    }
  }, [location.pathname]);

  Interceptor(dispatch);

  return (<>
    <ConnectionContext.Provider value={connection}>
      <ThemeProvider theme={theme}>
        <Layout isLoginPage={isLoginPage} isSettingsPage={isSettingsPage}>
          <Routes>
            <Route path={InternalAppRoutes.Root} element={<Login />} />
            <Route path={InternalAppRoutes.Sale} element={<AnagraficaSale />} />
            <Route path={InternalAppRoutes.Settings} element={<Settings />} />
            <Route path={InternalAppRoutes.Dashboard} element={<Dashboard />} />
            <Route path={InternalAppRoutes.Guida} element={<Guida />} />
            <Route path={InternalAppRoutes.Unknown} element={<ErrorPage />} />
          </Routes>
        </Layout>
        <Notification></Notification>
        <Loader />
      </ThemeProvider>
    </ConnectionContext.Provider>
  </>
  );
}

export default App;
