import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const dataTableSale = createSlice({
    name:'dataTableSale',
    initialState,
    reducers: {
        reloadingDataTableSale: (state) => {
            return !state;
         }
    }
})

export const { reloadingDataTableSale } = dataTableSale.actions;

const { reducer } = dataTableSale;

export default reducer;