import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Grid, Divider, Typography } from "@mui/material";
import { DataGrid, GridOverlay } from "@mui/x-data-grid";
import { CDCard, CDCardContent, CDCardHeader } from "../../../consts/CDCardStyles";
import CheckValidSortModel from "../../../functions/CheckValidSortModel";
import { SaleHeader } from "../../../consts/DataTableData/DataTableData";
import { getSalePaginated } from "../../../consts/urlAction";
import { useSelector, useDispatch } from "react-redux";
import {
  openEmptyDialogSala,
  setPageType,
} from "../../../reducers/dialogSala-reducer";
import handlerError from "../../../helpers/handlerError";
import { TitleDataTable } from "../../../components/TitleDataTable/TitleDataTable";
import { notificationOpen } from "../../../reducers/notification-reducer";
import { DialogPageTypes, ListenerSala } from "../../../consts/dictionary";
import Notification from "../../../components/Notification";
import { removeDataGridHeader } from "../../../functions/handlerDataGrid";
import TableToolbar from "../../../components/TableToolbar/TableToolbar";
import SalaDialog from "../../../components/Dialogs/SalaDialog/SalaDialog";
import ConnectionContext from "../../../context/ConnectionContext";
import { reloadingDataTableSale } from "../../../reducers/dataTableSale-reducer";

const urlBase = window.API_URL;

const typePage = "Sale";

const SaleDatatable = () => {
  const dispatch = useDispatch();
  const reloading = useSelector((state) => state.reloadingDataTableSale);
  const connection = useContext(ConnectionContext);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(rows.length);
  const [loading, setLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: SaleHeader.initialFieldName,
      sort: SaleHeader.initialFieldOrder,
    },
  ]);
  const [reloadDataTable, setReloadDataTable] = useState(true);
  const [numeroMassimoSale, setNumeroMassimoSale] = useState();
  const [totaleSale, setTotaleSale] = useState();

  useEffect(() => {
    if (reloadDataTable) {
      dispatch(reloadingDataTableSale());
      setReloadDataTable(false);
    }
  }, [reloadDataTable]);

  if (connection) {
    connection.on(ListenerSala.AggiornaElencoSale, (idUpdatedSala) => {
      setReloadDataTable(true);
    });
  }

  const StyledDataGrid = removeDataGridHeader(DataGrid, false);

  useEffect(() => {

    let isMounted = true;
    const { sortField, sortOrder } = CheckValidSortModel(
      sortModel,
      SaleHeader.initialFieldName
    );

    (async () => {
      setLoading(true);

      const { action, method } = getSalePaginated;
      let url = urlBase + action;

      const requestBody = {
        pageIndex: page,
        pageSize: pageSize,
        sortColumnName: sortField,
        sortDirection: sortOrder,
        applyPaginationAndSort: true
      };

      try {
        const response = await axios({
          method: method,
          url,
          data: requestBody,
        });
        if (isMounted) {
          const newRows = response.data.result.result;
          const newRowsCount = response.data.result.totalFiltered;
          const numeroTotaleSale = response.data.result.total;
          let numeroMaxElement = response.data.result.numeroMaxElement;

          setRows(newRows);
          setRowsCount(newRowsCount);
          setTotaleSale(numeroTotaleSale);
          setNumeroMassimoSale(numeroMaxElement);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [
    page,
    pageSize,
    reloading,
    /*sortModel
searchStatus,
  searchFilter,
  filterParameters, */
  ]);

  return (
    <>
      <Grid container mb={2}>
        <Grid item component={CDCard} xs={12} sm={12}>
          <CDCardHeader
            title={
              <>
                <TitleDataTable nameDataTable="Elenco Sale" />
              </>
            }
          />
          <Divider />
          <CDCardContent>
            {/* <AdvancedFilterPanel handleFilterData={handleFilterData} startValueRadioButton={filterParameters}/>
                <Divider sx={{ mb: 2 }} /> */}
            <Grid container item >
              <StyledDataGrid
                rows={rows}
                rowCount={rowsCount}
                getRowId={(row) => row.idSala}
                columns={SaleHeader.headers}
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onSortModelChange={(newSortModel) =>
                  setSortModel(newSortModel)
                }
                rowsPerPageOptions={[10, 25, 50, 100]}
                disableSelectionOnClick
                sortModel={sortModel}
                sortingMode="server"
                sortingOrder={["asc", "desc"]}
                paginationMode="server"
                loading={loading}
                sx={{ border: 0 }}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                autoHeight
                autoWidth
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: "Elementi per pagina",
                  }
                }}
                localeText={{
                  MuiTablePagination: {
                    labelDisplayedRows: ({ from, to, count }) =>
                      `da ${from} a ${to} di ${count}`,
                  },
                }}
                components={{
                  Toolbar: () => (
                    <TableToolbar
                      typePage={typePage}
                      enableAdd={totaleSale >= numeroMassimoSale ? false : true}
                      customButton={totaleSale >= numeroMassimoSale ? <p><b>Non è possibile aggiungere nuove sale.</b></p> : <p><b>({rows.length} / {numeroMassimoSale})</b></p> }
                      enableSearch={false}
                      handleAdd={() => {
                        dispatch(setPageType(DialogPageTypes.sale));
                        dispatch(openEmptyDialogSala("add"));
                      }}
                      initialSearchText={""}
                      startSearch={""}
                    />
                  ),
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      <Typography>Nessun elemento</Typography>
                    </GridOverlay>
                  ),
                  NoResultsOverlay: () => (
                    <GridOverlay>
                      <Typography>Nessun elemento</Typography>
                    </GridOverlay>
                  ),
                }}
              />
            </Grid>
          </CDCardContent>
        </Grid>
      </Grid>
      <Notification />
      <SalaDialog />
    </>
  );
};

export default SaleDatatable;

