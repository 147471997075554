import React from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  dialogButtonConfirm,
  dialogAddSala,
  dialogViewSala
} from "../../../../consts/dictionary";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationOpen } from "../../../../reducers/notification-reducer";
import { closeDialogSala } from "../../../../reducers/dialogSala-reducer";
import FormAddSala from "./FormAddSala";

export default function DialogAddSala({
  isOpen,
  rowInfo,
  isEdit
}) {
  const [isRenderingDialog, setIsRenderingDialog] = useState(false);
  const [sala, setsala] = useState();
  const dispatch = useDispatch();

  return (
    <>
      {!isRenderingDialog && (
        <Dialog
          open={isOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle sx={{ color: '#002E5D' }}>
            {dialogAddSala.DialogTitle}
          </DialogTitle>
          <DialogContent sx={{ paddingRight: '1px!important', paddingBottom: '1px!important' }}>
            <FormAddSala/>
          </DialogContent>
         {/*  {!isEdit && <> <DialogActions>
            <Button onClick={() => dispatch(closeDialogSala())}>
              {dialogButtonConfirm.Indietro}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="primaryButton"
              onClick={() => dispatch(closeDialogSala())}
            >
              {dialogButtonConfirm.Confirm}
            </Button>
          </DialogActions></>} */}
        </Dialog >
      )
      }
    </>
  );
}
