import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../reducers/userData-reducer';
import notificationReducer from '../reducers/notification-reducer';
import appLoaderReducer from '../reducers/appLoader-reducer';
import dialogSalaReducer from "../reducers/dialogSala-reducer";
import dialogSlideReducer from "../reducers/dialogSlide-reducer";
import dataTableSaleReducer from "../reducers/dataTableSale-reducer";
import reloadingDataTableSale from "../reducers/dataTableSale-reducer";
import reloadingDataTableSlides from "../reducers/dataTableSlides-reducer";

const store = configureStore({
    reducer: {
        userData: userReducer,
        notification: notificationReducer,
        appLoader: appLoaderReducer,
        dialogSala: dialogSalaReducer,
        dialogSlide: dialogSlideReducer,
        dataTableSale: dataTableSaleReducer,
        reloadingDataTableSale: reloadingDataTableSale,
        reloadingDataTableSlides: reloadingDataTableSlides
    }
});

export default store; 