import axios from "axios";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Divider, Typography, Tooltip } from "@mui/material";
import { DataGrid, GridOverlay, GridActionsCellItem } from "@mui/x-data-grid";
import { CDCard, CDCardContent, CDCardHeader } from "../../../consts/CDCardStyles";
import { SlidesHeader } from "../../../consts/DataTableData/DataTableData";
import { openDialogMoveSlide, openEmptyDialogSlide, setPageType, closeDialogMoveSlide } from "../../../reducers/dialogSlide-reducer";
import { hideLoader, showLoader } from "../../../reducers/appLoader-reducer";
import handlerError from "../../../helpers/handlerError";
import { TitleDataTable } from "../../../components/TitleDataTable/TitleDataTable";
import { notificationOpen } from "../../../reducers/notification-reducer";
import { DialogPageTypes } from "../../../consts/dictionary";
import Notification from "../../../components/Notification";
import { getSlides } from "../../../consts/urlAction";
import { removeDataGridHeader } from "../../../functions/handlerDataGrid";
import TableToolbar from "../../../components/TableToolbar/TableToolbar";
import SlideDialog from "../../../components/Dialogs/SlideDialog/SlideDialog";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import "../../../index.css";

const typePage = "Guida";
const urlBase = window.API_URL;

const SlidesDataTable = ({ idSala }) => {

  const dispatch = useDispatch();
  const reloading = useSelector((state) => state.reloadingDataTableSlides);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const StyledDataGrid = removeDataGridHeader(DataGrid, false);

  useEffect(() => {
    let isMounted = true;
    dispatch(showLoader());

    (async () => {
      setLoading(true);

      const { action, method } = getSlides;
      let queryString = "?idSala=" + idSala + "&includiFile=" + false + "&includiThumbnail=" + true;
      let url = urlBase + action + queryString;

      try {
        const response = await axios({
          method: method,
          url
        });

        if (isMounted) {
          const newRows = response.data.result;
          setRows(newRows);
          setLoading(false);
          dispatch(hideLoader());
        }
      } catch (error) {
        console.error(error);
        const notificationError = handlerError(error);
        dispatch(notificationOpen(notificationError));
        setLoading(false);
        dispatch(hideLoader());
      }
    })();
    return () => {
      isMounted = false;
    };
  }, [reloading]);

  useEffect(() => {
    if (selectionModel.length > 0) {
      const selectedRowData = rows.filter((row) =>
        row.idSlide === selectionModel[0]
      )[0];
      const maxPosition = rows.reduce((max, row) => {
        return row.posizione > max ? row.posizione : max;
      }, 0);

      dispatch(setPageType(DialogPageTypes.guida));
      dispatch(openDialogMoveSlide({ dialogType: "move", slideInfo: selectedRowData, slides: rows, maxPosition: maxPosition }));
    } else {
      dispatch(closeDialogMoveSlide());
    }
  }, [selectionModel]);

  const handleClone = () => {
    dispatch(setPageType(DialogPageTypes.guida));
    dispatch(openEmptyDialogSlide("clone"));
  }

  return (
    <>
      <Grid container mb={2}>
        <Grid item component={CDCard} xs={12} sm={12}>
          <CDCardHeader
            title={
              <>
                <TitleDataTable nameDataTable={'Guida della Sala'} />
              </>
            }
          />
          <Divider />
          <CDCardContent>
            <Grid container item>
              <StyledDataGrid
                rows={rows}
                getRowId={(row) => row.idSlide}
                columns={SlidesHeader.headers}
                loading={loading}
                sx={{ border: 0 }}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={(selection) => {
                  if (selection.length > 1) {
                    const selectionSet = new Set(selectionModel);
                    const result = selection.filter((s) => !selectionSet.has(s));
                    setSelectionModel(result);
                  } else {
                    setSelectionModel(selection);
                  }
                }}
                autoHeight
                autoWidth
                hideFooter
                components={{
                  Toolbar: () => (
                    <TableToolbar
                      typePage={typePage}
                      enableAdd={true}
                      enableSearch={false}
                      customButton={
                        <GridActionsCellItem
                          icon={
                            <Tooltip title="Clona">
                              <FileCopyIcon sx={{ fontSize: 30, color: '#002E5D' }} />
                            </Tooltip>
                          }
                          label="Clona"
                          onClick={() => handleClone()}
                        />
                      }
                      handleAdd={() => {
                        dispatch(setPageType(DialogPageTypes.guida));
                        dispatch(openEmptyDialogSlide("add"));
                      }}
                      initialSearchText={""}
                      startSearch={""}
                    />
                  ),
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      <Typography>Nessun elemento</Typography>
                    </GridOverlay>
                  ),
                  NoResultsOverlay: () => (
                    <GridOverlay>
                      <Typography>Nessun elemento</Typography>
                    </GridOverlay>
                  )
                }}
              />
            </Grid>
          </CDCardContent>
        </Grid>
      </Grid>
      <Notification />
      <SlideDialog idSala={idSala} setSelectionModel={setSelectionModel} />
    </>
  );
};

export default SlidesDataTable;

