/*### USER ###*/
export const loginUser = { action: "User/Login", method: "post" }

//SALE
export const getSalePaginated = { action: "Sala/ElencoSale", method: "post" }
export const getSalaById = { action: "Sala/GetSalaById?idSala=", method: "get", withDettaglio: "&withDettaglio=true" }
export const addSala = { action: "Sala/CreaSala", method: "post" }
export const editSala = { action: "Sala/ModificaSala", method: "post" }
export const deleteSala = { action: "Sala/RimuoviSala?idSala=", method: "post" }
export const resetSala = { action: "Sala/SvuotaSessioneSala?idSala=", method: "post" }
export const getImpostazioniGlobali = { action: "Sala/GetImpostazioniGlobaliSale", method: "get" }
export const editImpostazioniGlobali = { action: "Sala/ModificaImpostazioniSale", method: "post" }
export const getRispostePredefiniteSos = { action: "Sala/GetRispostePredefiniteSos", method: "get" }
export const inviaRispostaSos = { action: "Sala/InviaRispostaSos", method: "post"}
export const chiudiSOS = {action: "Sala/ChiudiSos?idSala=", method:"post"}

/*### GUIDA ###*/
export const getSlides = { action: "Guida/GetElencoSlide", method: "get" }
export const addSlide = { action: "Guida/AggiungiSlide", method: "post" }
export const editSlide = { action: "Guida/ModificaSlide", method: "post" }
export const deleteSlide = { action: "Guida/RimuoviSlide", method: "post" }
export const cloneSlide = { action: "Guida/ClonaSlide", method: "post" }
export const moveSlide = { action: "Guida/SpostaSlide", method: "post" }
export const getElencoSale = { action: "Guida/GetElencoSaleConSlide", method: "get" }