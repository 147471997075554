export function initialValuesSlideEdit(rowInfo) {
  return {
    idSlide: rowInfo.idSlide,
    nome: rowInfo.titolo,
    abilitaModificaSorgente: false,
    file:"",
    thumbnail:""
  }
};

export function initialValuesSlideAdd() {
  return {
    nome: ""
  }
};