import {
  openDialogSala,
  setPageType,
} from "../../../reducers/dialogSala-reducer";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DialogPageTypes, InternalAppRoutes, Palette } from '../../../consts/dictionary';
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import ResetTvIcon from '@mui/icons-material/ResetTv';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import SosIcon from '@mui/icons-material/Sos';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import { useNavigate } from 'react-router-dom';
import { Badge } from "@mui/material";

const ButtonBoxSale = ({ rowId, rowInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDialogToEdit = ({ dialogType, rowInfo, isEdit }) => {
    dispatch(setPageType(DialogPageTypes.sale));
    dispatch(openDialogSala({ dialogType, rowInfo, isEdit }));
  };

  const handleRoute = ({ route, rowInfo }) => {
    navigate(route, {
      rowInfo: rowInfo,
    })
  };

  return (
    <>
    {rowInfo.sosAttivo && <GridActionsCellItem
        icon={
          <Tooltip title="Chiudi S.O.S.">
            <ReportOffIcon sx={{ fontSize: 25, color: Palette.Error }} />
          </Tooltip>
        }
        label="Chiudi S.O.S."
        onClick={() => handleDialogToEdit({ dialogType: "chiudiSOS", rowInfo, isEdit: false })}
      />}
      {rowInfo.sosAttivo && <GridActionsCellItem
        icon={
          <Tooltip title="Risposta S.O.S.">
            <SosIcon sx={{ fontSize: 25, color: Palette.Error }} />
          </Tooltip>
        }
        label="Risposta S.O.S."
        onClick={() => handleDialogToEdit({ dialogType: "rispostaSOS", rowInfo, isEdit: false })}
      />}
      <GridActionsCellItem
        icon={
          <Tooltip title="Riavvio Mini PC">
            <SyncProblemIcon sx={{ fontSize: 25, color: '#002E5D' }} />
          </Tooltip>
        }
        label="Riavvio Mini PC"
        onClick={() => handleDialogToEdit({ dialogType: "resetPC", rowInfo, isEdit: false })}
      />
      <GridActionsCellItem
        icon={
          <Tooltip title="Reset Postazione">
            <ResetTvIcon sx={{ fontSize: 25, color: '#002E5D' }} />
          </Tooltip>
        }
        label="Reset Postazione"
        onClick={() => handleDialogToEdit({ dialogType: "resetSala", rowInfo, isEdit: false })}
      />
      <GridActionsCellItem
        icon={
          <Tooltip title="Gestione Guida">
            <SlideshowIcon sx={{ fontSize: 25, color: '#002E5D' }} />
          </Tooltip>
        }
        label="Gestione Guida"
        onClick={() => handleRoute({ route: InternalAppRoutes.Guida + "?idSala=" + rowInfo.idSala, rowInfo })}
      />
      <GridActionsCellItem
        icon={
          rowInfo.statoWarning ? <Badge color="error" variant="dot">
            <Tooltip title="Dashboard">
              <SpaceDashboardIcon sx={{ fontSize: 25, color: '#002E5D' }} />
            </Tooltip>
          </Badge> :
            <Tooltip title="Dashboard">
              <SpaceDashboardIcon sx={{ fontSize: 25, color: '#002E5D' }} />
            </Tooltip>
        }
        label="Dashboard"
        onClick={() => handleRoute({ route: InternalAppRoutes.Dashboard + "?idSala=" + rowInfo.idSala, rowInfo })}
      />
      <GridActionsCellItem
        icon={
          <Tooltip title="Modifica">
            <EditIcon sx={{ fontSize: 25, color: '#002E5D' }} />
          </Tooltip>
        }
        label="Modifica"
        onClick={() => handleDialogToEdit({ dialogType: "edit", rowInfo, isEdit: true })}
      />
      <GridActionsCellItem
        icon={
          <Tooltip title="Elimina">
            <DeleteIcon sx={{ fontSize: 25, color: '#002E5D' }} />
          </Tooltip>
        }
        label="Elimina"
        onClick={() => handleDialogToEdit({ dialogType: "delete", rowInfo, isEdit: false })}
      />
    </>
  );
};

export default ButtonBoxSale;
